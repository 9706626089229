<template>
    <v-container v-if="internalUse">
        <v-row class="center-x ma-4">
            <v-spacer />
            <v-btn outlined color="primary" @click="dialogs.createJobForm.open=true">
                <v-icon left large >mdi-plus</v-icon>
                Create New Job
            </v-btn>
        </v-row>

        <v-row class="body-1">
            <span class="d-flex align-end">Recent Jobs</span>
            <v-spacer />
            <!-- <v-btn-toggle v-model="jobsSortBy" @change="sortJobs"
                mandatory dense color="primary" rounded class="mb-4"
            >
                <v-btn :value="1" class="px-2">
                    <v-icon small>mdi-calendar</v-icon> NEW
                </v-btn>
                <v-btn :value="2" class="px-2">
                    <v-icon small>mdi-calendar</v-icon> OLD
                </v-btn>
                <v-btn :value="3" class="px-2">
                    <v-icon>mdi-order-alphabetical-ascending</v-icon>
                </v-btn>
                <v-btn :value="4" class="px-2">
                    <v-icon>mdi-order-alphabetical-descending</v-icon>
                </v-btn>
            </v-btn-toggle> -->
            <v-spacer />
        </v-row>
        <v-layout wrap>
            <v-flex xs12 v-for="(i, j) in jobCount" :key="i" class="px-2 py-1">
                <v-skeleton-loader v-if="loadingJobs"
                    type="article, list-item-avatar-three-line, actions"
                    class="ma-2"
                ></v-skeleton-loader>
                <JobCard v-else :jobDetails="allActiveJobs[j]" />
            </v-flex>
        </v-layout>
        

        <CreateJobForm
            :usedJobNames="usedJobNames"
            @update-jobs="updateData()"
        />

        <v-dialog v-model="dialogs.retireJobConfirmation.open"
            max-width="500px" overlay-opacity="0.85"
        >
            <v-card>
                <v-card-title>
                    {{currentlyRetired ? 'Activating' : 'Retiring'}} a Job
                </v-card-title>
                <v-card-text>
                    Please confirm you would like to {{currentlyRetired ? 'activate' : 'retire'}}
                    <br/>
                    <span class="center-x white--text body-1 pt-4">
                        {{dialogs.retireJobConfirmation.details.jobName}}
                        <span class="overline grey--text mt-n1 ml-2">
                            J-ID {{dialogs.retireJobConfirmation.details.jobID}}
                        </span>
                    </span>
                    <span class="caption">
                        This action can be reversed, but
                        retired jobs do not show up FluxHost
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-btn outlined 
                        @click="dialogs.retireJobConfirmation.open=false"
                        :disabled="statusChangeInProgress"
                    >
                        Back
                    </v-btn>
                    <v-spacer />
                     <v-icon v-if="statusChangeInProgress">
                        mdi-loading mdi-spin
                    </v-icon>
                    <v-spacer />
                    <v-btn outlined color="primary"
                        @click="changeRetireStatus()"
                        :disabled="statusChangeInProgress"
                    >
                        {{currentlyRetired ? 'Activate' : 'Retire'}}
                        J-ID {{dialogs.retireJobConfirmation.details.jobID}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
  </v-container>
</template>

<script>
import {mapState} from 'vuex';
import CreateJobForm from '../components/CreateJobModal.vue';
import JobCard from '../components/JobCard.vue';

export default {
    components: {
        CreateJobForm,
        JobCard,
    },
    data: function(){
        return {
            loadingJobs: true,
            jobCount: 12,
            allActiveJobs: {},
            statusChangeInProgress: false,
            // filterOptions: ['2 Series', '3 Series']
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'dialogs',
            'internalUse'
        ]),
        usedJobNames: function() {
            return Object.keys(this.allActiveJobs).map(j => {
                return this.allActiveJobs[j].name
            })
        },
        currentlyRetired: function() {
            return this.dialogs.retireJobConfirmation.details.currentStatusRetired;
        }
    },
    mounted() {
        this.updateData();
    },
    methods: {
        async updateData() {
            let vm = this;
            try {
                let t = await vm.$auth.getTokenSilently();

                this.loadingJobs = true;
                let response = await vm.$http.get(vm.baseURL+'/database/allJobs', {
                    headers: { Authorization: `Bearer ${t}`}});

                this.allActiveJobs = response.data.body;
                this.allActiveJobs.forEach(j => {
                    j.user = j.first_name + " " + j.last_name

                    if (j.mat_config_name_2x != undefined) {
                        j.config_series = 2;
                    }
                    else if (j.mat_config_name_3x != undefined) {
                        j.config_series = 3;
                    }
                })
                
                this.jobCount = this.allActiveJobs.length;
                this.loadingJobs = false;
                console.log("Response from /database/allJobs", this.allActiveJobs);
            }
            catch(e) {
                console.log("Failed to get jobs: ", e);
            }
        },
        async changeRetireStatus() {
            let vm = this;
            try {
                let t = await vm.$auth.getTokenSilently();

                this.statusChangeInProgress = true;
                await vm.$http.post(vm.baseURL+'/database/changeJobStatus', 
                {
                    status: !this.dialogs.retireJobConfirmation.details.currentStatusRetired,
                    jobId: this.dialogs.retireJobConfirmation.details.jobID
                },{
                    headers: { Authorization: `Bearer ${t}`}
                });

                this.dialogs.retireJobConfirmation.open = false;
                this.statusChangeInProgress = false;
                this.updateData();
                console.log("Successfully changed retire status to", !this.dialogs.retireJobConfirmation.details.currentStatusRetired, "for job id", this.dialogs.retireJobConfirmation.details.jobID);
            }
            catch(e) {
                console.log("Failed to retire job: ", e);
            }
        },
    },
};
</script>
