<template>
    <div>
        <v-list-item class="px-2">
            <v-list-item-avatar>
                <v-img :src="$auth.user.picture"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title class="body-1 pb-0">
                    {{$auth.user.given_name + " " + $auth.user.family_name}}
                    <!-- <v-btn icon>
                        <v-icon>mdi-cog</v-icon>
                    </v-btn> -->
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="logout()">
            <v-list-item-icon>
                <v-icon></v-icon>
            </v-list-item-icon>
            <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
    </div>

</template>


<script>
// import {mapMutations, mapGetters} from 'vuex';
export default {
    name: 'UserProfile',
    components: {
        
    },
    data: () => ({
        //
    }),
    computed: {
        // ...mapGetters([
        // ])
    },
    methods: {
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin
            });
        },
    }
}

</script>

<style>

</style>