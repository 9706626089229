<template>
    <v-card class="grey darken-3 mx-6 my-10" align="center">
        <v-toolbar
            dense
            color="primaryDark2 lighten-1"
            dark
            height="38px"
        >
            <v-toolbar-title class="toolbar-title">
                Results
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-btn
                            @click="openPrintEditDialog()"
                            :disabled="disableEdit"
                            color="white"
                            small
                            rounded
                        >
                            <v-icon
                                medium
                                color="primary"
                            >
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>
                    {{disableEdit ? 'Disabled for Customer Print' : 'Edit Result'}}
                </span>
            </v-tooltip>
        </v-toolbar>
        <v-expansion-panels class="mt-1">
            <v-expansion-panel class="grey darken-3">
                <v-expansion-panel-header>
                    <v-col cols=12 class="py-0 no-click">
                        <v-text-field
                            v-model="status[selectedPrint.successText].text"
                            label="Successful"
                            prepend-icon="mdi-clipboard-text-outline"
                            readonly class="no-underline"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        v-if="selectedPrint.successful == false"
                        cols=12
                        class="py-0 no-click"
                    >
                        <v-textarea
                            v-model="selectedPrint.failure_display"
                            label="Failure Type: Reason"
                            prepend-icon="mdi-alert-outline"
                            rows="1"
                            auto-grow
                            no-resize
                            readonly class="no-underline"
                        ></v-textarea>
                    </v-col>
                    <v-col cols=12 class="py-0 no-click">
                        <v-text-field
                            v-model="layersCompleted"
                            label="Layers Complete"
                            readonly class="no-underline"
                        ></v-text-field>
                    </v-col>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <PrintEditTable :printEditHistoryList="printEditHistoryList"/>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <PrintEditForm
            @update-print-history="getPrintDetails()"
            :selectedPrint="this.selectedPrint"/>
    </v-card>
</template>

<script >

import {mapState} from 'vuex';
import PrintEditTable from './PrintEditTable';
import PrintEditForm from './PrintEditForm';

export default {
    props: {
        printEditHistoryList: {
            type: Array,
            required: true
        },
        disableEdit: {
            type: Boolean,
            required: true
        },
    },
    components: {
        PrintEditTable,
        PrintEditForm,
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'status',
            'dialogs',
            'iam',
        ]),
        layersCompleted() {
            return this.printEditHistoryList[0].layersComplete
                + " (" + this.printEditHistoryList[0].percentComplete + "%)";
        },
        selectedPrint() {
            return this.printEditHistoryList[0];
        }
    },
    async mounted() {
    },
    methods: {
        openPrintEditDialog() {
            this.dialogs.printEditForm.open = true;
        },
        getPrintDetails() {
            this.$emit('update-print-history');
        }
    }
}
</script>

<style scoped>
</style>