<template>
    <v-dialog v-model="dialogs.printEditForm.open" persistent max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline"> 
                    {{'Edit Print ' + selectedPrint.id + ' Result '}}
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form 
                        v-model="validForm"
                        autocomplete="off"
                        ref="printEditForm"
                        class="pa-2"
                    >
                        <v-row dense>
                            <v-col cols ="12" class="px-0">
                                <v-select
                                    v-model="successResult"
                                    :items="resultOptions"
                                    item-text="text"
                                    item-value="value"
                                    label="Success/Fail"
                                    :rules="[rules.requiredSelect]"
                                    @change="handleResultChange"
                                ></v-select> 
                            </v-col>
                            <v-col cols="12">
                                    <TwoPanelDropDown
                                        ref="twoPanelDropDown"
                                        :allOptions="failureModes"
                                        title="Failure Reason"
                                        leftPanelLabel="Failure Type"
                                        rightPanelLabel="Failure Reason"
                                        @selected-option="selectFailureMode"
                                        :disabled="successResult==true"
                                        clearable
                                    />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn 
                    id="closeBtn"
                    @click="closePrintEditDialog()"
                    color="primary"
                    text
                >
                    Close
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                    id="saveBtn"
                    :disabled="!validForm"
                    @click="submitPrintEdit()"
                    color="primary" text
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex';
import TwoPanelDropDown from './TwoPanelDropDown.vue';
export default {
    components: {
        TwoPanelDropDown,
    },
    props: {
        selectedPrint: {
            type: Object,
            required: true
        },
    },
    data: function() {
        return {
            failureModes: [],
            validForm: false,
            resultOptions: [
                {
                    text: "Success",
                    value: true,
                },
                {
                    text: "Failed",
                    value: false,
                }
            ],
            selectedFailureReason: this.selectedPrint.failure_id,
            selectedFailureReasonId: undefined,
            successResult: this.selectedPrint.successful,
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'dialogs',
            'rules',
        ]), 
    },
    mounted() {
        this.getFailureModes();
    },
    methods: {
        async getFailureModes() {
            let t = await this.$auth.getTokenSilently();

            this.$http.get(this.baseURL+'/database/failureMode', {
                    headers: { Authorization: `Bearer ${t}` }})
            .then((response) => {
                response.data.body.forEach((option) => {
                if (!this.failureModes.hasOwnProperty(option.type)) {
                    this.failureModes[option.type] = {
                        disabled: false,
                        list: []
                    };
                }
                this.failureModes[option.type]
                    .list.push({desc: option.description, id: option.id})});
            })
        },
        async submitPrintEdit() {
            if (this.successResult==true) {
                this.selectedFailureReason = null;
                this.selectedFailureReasonId = null;
            }

            let t = await this.$auth.getTokenSilently();

            let params = {
                print_id: this.selectedPrint.id,
                successful: this.successResult,
                failure_reason: this.selectedFailureReasonId,
                percent_completed: this.selectedPrint.percentComplete,
                output_zip: this.selectedPrint.output_zip
            };

            console.log("making post: ", params);

            this.$http.post(this.baseURL+'/endPrint',  params,
                {headers: {Authorization: `Bearer ${t}`}})
            .then((response) => {
                console.log("Got response for /endPrint: ", response);
                this.$emit('update-print-history');
            })
            .catch(error => {
                console.log("Error for /endPrint: ", error)
            });
            
            this.closePrintEditDialog();
        },
        closePrintEditDialog() {
            this.dialogs.printEditForm.open = false;
        },
        handleResultChange() {
            if (this.successResult == true) {
                this.$refs.twoPanelDropDown.clearSelection();
            }
        },
        selectFailureMode(selected) {
            if (selected) {
                this.selectedFailureReason = selected.desc;
                this.selectedFailureReasonId = selected.id;
            }
            else {
                this.selectedFailureReason = undefined;
                this.selectedFailureReasonId = undefined;
            }
        }
    }
}
</script>