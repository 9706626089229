<template>
      <div>
        <v-card class="ma-3">
            <v-card-title>
                {{ $auth.user.name }}
            </v-card-title>
            <v-card-text>
                <img :src="$auth.user.picture">
                <p>{{ $auth.user.email }}</p>
                <pre>{{ JSON.stringify($auth.user, null, 2) }}</pre>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn outlined disabled @click="testAuth()">
                    Test Auth
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
      </div>
</template>

<script>
export default {
    data() {
        return {
            apiMessage: '',
        }
    },
    mounted(){
        this.$auth.getTokenSilently()
        .then(t => {
            console.log("token: ", t);
        })
    },
    methods: {
        async testAuth() {
            let t = await this.$auth.getTokenSilently();
            this.$http.get('http://localhost:8081/testauth', {
                headers: {
                    Authorization: `Bearer ${t}`
                }
            })
            .then((resp) => {
                this.apiMessage = resp;
                console.log(resp);
            })
        }
    }

}
</script>

<style scope>

</style>
