var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-10 mx-6",attrs:{"color":"grey darken-3","align":"center"}},[_c('v-toolbar',{attrs:{"dense":"","color":"primaryDark2 lighten-1","dark":"","height":"38px"}},[_c('v-toolbar-title',{staticClass:"toolbar-title"},[_vm._v("Attachments")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"disabled":_vm.options.disableAdd,"small":"","color":"white","rounded":""},on:{"click":_vm.handleFileImport}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.options.disableAdd ? 'Disabled for Customer Print' : 'Add Attachment')+" ")])])],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file"},on:{"change":_vm.saveAttachment}}),_c('v-row',{staticClass:"ma-2"},[_c('v-slide-group',{attrs:{"show-arrows":""}},_vm._l((_vm.retrievedAttachments),function(attachment){return _c('v-slide-item',{key:attachment.id,staticClass:"my-4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{staticClass:"mx-2 d-flex align-center justify-center",attrs:{"color":"blue-grey darken-1","width":"225","height":"225","hover":""},on:{"click":toggle}},[(_vm.imageTypes.includes(attachment.filetype))?_c('v-img',{attrs:{"src":attachment.fileUrl,"aspect-ratio":1}}):_c('div',[_c('v-row',{staticClass:"body-1 mx-2 mb-3 text-wrap",attrs:{"justify":"center","align":"center"}},[_c('span',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(attachment.name)+" ")])]),_c('v-icon',{attrs:{"color":"white","x-large":""}},[_vm._v(" mdi-file-document-outline ")])],1),_c('v-overlay',{attrs:{"value":active,"opacity":".8","absolute":""}},[_c('v-card-title',{staticClass:"body-1 center-x"},[_vm._v(" "+_vm._s(attachment.name)+" ")]),_c('v-row',{staticClass:"mt-10"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"text":"","disabled":_vm.options.disableDelete}},[_c('v-icon',{attrs:{"color":"grey lighten-2","size":"30"},on:{"click":function($event){return _vm.removeAttachment(
                                            attachment.id)}}},[_vm._v(" mdi-trash-can-outline ")])],1)],1),_c('v-col',[_c('v-btn',{attrs:{"disabled":!attachment.fileUrl,"text":""}},[_c('v-icon',{attrs:{"color":"primary","size":"30"},on:{"click":function($event){return _vm.downloadAttachment(
                                            attachment)}}},[_vm._v(" mdi-download ")])],1)],1)],1)],1)],1)]}}],null,true)})}),1),(!_vm.retrievedAttachments
            || _vm.retrievedAttachments.length == 0)?_c('v-card',{staticClass:"d-flex align-center justify-center",attrs:{"flat":"","color":"transparent","align":"center","height":"150","width":"100%"}},[_c('span',{staticClass:"overline grey--text"},[_vm._v("No Attachments")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }