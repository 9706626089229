var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"no-click"},[_c('v-sheet',{staticClass:"mt-3",attrs:{"outlined":"","color":"grey darken-2","rounded":""}},[_c('v-card',{attrs:{"outlined":"","color":"gray3"}},[_c('v-card-title',[_vm._v(" Basic Settings ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(_vm.configData.name)?_c('v-text-field',{staticClass:"no-underline",attrs:{"label":"Name","value":_vm.configData.name}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(_vm.configData.config_series)?_c('v-text-field',{staticClass:"no-underline",attrs:{"label":"Series","value":_vm.configData.config_series}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(_vm.configData.config_series == 3)?_c('v-text-field',{staticClass:"no-underline",attrs:{"label":"Material Type","value":_vm.configData.material_type}}):_vm._e()],1)],1)],1)],1)],1),_c('v-sheet',{staticClass:"mt-3",attrs:{"outlined":"","color":"grey darken-2","rounded":""}},[_c('v-card',{attrs:{"outlined":"","color":"gray3"}},[_c('v-card-title',[_vm._v(" CKM Settings ")]),_c('v-card-text',[(_vm.configData.config_series == 3)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"no-underline",attrs:{"label":"Ambient Heat Status","value":_vm.ambientHeatOff ? 'OFF' : 'ON'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(!_vm.ambientHeatOff)?_c('v-text-field',{staticClass:"no-underline",attrs:{"label":_vm.materialParams
                                .ckm_settings
                                .amb_target_temp
                                .label,"value":_vm.concatParamValue(
                                _vm.configData.amb_target_temp,
                                _vm.materialParams
                                .ckm_settings
                                .amb_target_temp
                                .units)}}):_vm._e()],1)],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[(_vm.configData.config_series == 2)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"no-underline",attrs:{"label":"CKM Mode","value":!_vm.ckmModeOn ? 'CKM OFF: reservoir only, no heat' : 'CKM LV: circulation'}})],1):_vm._e(),(_vm.configData.config_series == 3
                            || _vm.ckmModeOn)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"no-underline",attrs:{"label":"CKM Speed","value":_vm.concatParamValue(
                                _vm.configData.ckm_mode
                                    || _vm.configData.rsvr_temp_pump_lower,
                                'rpm')}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[(_vm.configData.rsvr_tgt_level 
                                && _vm.configData.config_series == 3)?_c('v-text-field',{staticClass:"no-underline",attrs:{"label":_vm.materialParams
                                .ckm_settings
                                .rsvr_tgt_level
                                .label,"value":_vm.concatParamValue(
                                _vm.configData.rsvr_tgt_level,
                                _vm.materialParams
                                .ckm_settings
                                .rsvr_tgt_level
                                .units)}}):_vm._e()],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{staticClass:"no-underline",attrs:{"label":"CKM Heat Status","value":_vm.ckmHeatOff ? 'OFF' : 'ON'}})],1),_vm._l((_vm.filterObject(
                            _vm.materialParams.ckm_settings)),function(value,setting){return _c('v-col',{key:setting,attrs:{"cols":"12","sm":"6","md":"4"}},[(!_vm.ckmHeatOff)?_c('v-text-field',{staticClass:"no-underline",attrs:{"label":value.label,"value":_vm.concatParamValue(
                                _vm.configData[setting], 
                                value.units)}}):_vm._e()],1)})],2)],1)],1)],1),_c('v-sheet',{staticClass:"mt-3",attrs:{"outlined":"","color":"grey darken-2","rounded":""}},[_c('v-card',{attrs:{"outlined":"","color":"gray3"}},[_c('v-card-title',[_vm._v(" Peel Settings ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.materialParams.peel_settings),function(value,setting){return _c('v-col',{key:setting,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"no-underline",attrs:{"label":value.label,"value":_vm.concatParamValue(
                                _vm.configData[setting],
                                value.units)}})],1)}),1)],1)],1)],1),_c('v-sheet',{staticClass:"mt-3",attrs:{"outlined":"","color":"grey darken-2","rounded":""}},[_c('v-card',{attrs:{"outlined":"","color":"gray3"}},[_c('v-card-title',[_vm._v(" Plunge Settings ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.materialParams.plunge_settings),function(value,setting){return _c('v-col',{key:setting,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"no-underline",attrs:{"label":value.label,"value":_vm.concatParamValue(
                                _vm.configData[setting],
                                value.units)}})],1)}),1)],1)],1)],1),_c('v-sheet',{staticClass:"mt-3",attrs:{"outlined":"","color":"grey darken-2","rounded":""}},[_c('v-card',{attrs:{"outlined":"","color":"gray3"}},[_c('v-card-title',[_vm._v(" UV Settings ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.materialParams.uv_settings),function(value,setting){return _c('v-col',{key:setting,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"no-underline",attrs:{"label":value.label,"value":_vm.concatParamValue(
                                _vm.configData[setting],
                                value.units)}})],1)}),1)],1)],1)],1),(_vm.internalUse && _vm.configData.config_series == 3)?_c('v-sheet',{staticClass:"mt-3",attrs:{"outlined":"","color":"grey darken-2","rounded":""}},[_c('v-card',{attrs:{"outlined":"","color":"gray3"}},[_c('v-card-title',[_c('v-col',{staticClass:"px-0"},[_vm._v(" Default Settings ")]),_c('v-col',{staticClass:"overline primary--text",attrs:{"align":"right"}},[_vm._v(" Internal Use ")])],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.defaultValuesMap),function(value,setting){return _c('v-col',{key:setting,attrs:{"cols":"12","sm":"6","md":"3"}},[(!_vm.matConfigDefaultValueExceptions.includes(setting))?_c('v-text-field',{staticClass:"no-underline",attrs:{"label":setting=='donotoverridegcode'
                                ? 'doNotOverrideGCode' : setting,"value":_vm.configCreationDisplay && _vm.configData[setting]==null ? _vm.concatParamValue(_vm.defaultValuesMap[setting]) : _vm.concatParamValue(_vm.configData[setting]),"success":_vm.configCreationDisplay && _vm.configData[setting]==null ? false : _vm.configData[setting] != _vm.defaultValuesMap[setting]}}):_vm._e()],1)}),1)],1)],1)],1):_vm._e(),(_vm.internalUse && _vm.configData.config_series == 3)?_c('v-sheet',{staticClass:"mt-3",attrs:{"outlined":"","color":"grey darken-2","rounded":""}},[_c('v-card',{attrs:{"outlined":"","color":"gray3"}},[_c('v-card-title',[_c('v-col',{staticClass:"px-0"},[_vm._v(" Override Configs ")]),_c('v-col',{staticClass:"overline primary--text",attrs:{"align":"right"}},[_vm._v(" Internal Use ")])],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.overrideConfigs),function(value,setting){return _c('v-col',{key:setting,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"no-underline",attrs:{"label":setting,"value":_vm.concatParamValue(
                                _vm.overrideConfigs[setting],
                                null)}})],1)}),1)],1)],1)],1):_vm._e(),(_vm.internalUse && _vm.configData.config_series == 3)?_c('v-sheet',{staticClass:"mt-3",attrs:{"outlined":"","color":"grey darken-2","rounded":""}},[_c('v-card',{attrs:{"outlined":"","color":"gray3"}},[_c('v-card-title',[_c('v-col',{staticClass:"px-0"},[_vm._v(" Print Quality Configs ")]),_c('v-col',{staticClass:"overline primary--text",attrs:{"align":"right"}},[_vm._v(" Internal Use ")])],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[(_vm.configData.material_scale_factor!=undefined)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-text-field',{staticClass:"no-underline",attrs:{"label":"Material Scale Factor","value":_vm.configData.material_scale_factor}})],1):_vm._e(),_vm._l((_vm.printQualityConfigs),function(value,setting){return _c('v-col',{key:setting,attrs:{"cols":"12","sm":"6","md":"3"}},[(setting != 'moduleList' && typeof setting == 'string')?_c('v-text-field',{staticClass:"no-underline",attrs:{"label":setting,"value":_vm.concatParamValue(
                                _vm.printQualityConfigs[setting],
                                null)}}):_vm._e()],1)}),(_vm.printQualityConfigs && _vm.printQualityConfigs.moduleList)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"no-underline",attrs:{"label":"moduleList"}}),_c('pre',{staticClass:"brightText--text",attrs:{"id":"json"}},[_vm._v(_vm._s(_vm.printQualityConfigs.moduleList)+"\n                        ")])],1):_vm._e()],2)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }