<template>
      <div>
        <v-card>
            <v-card-title>
                Email verification
            </v-card-title>
            <v-card-text class="text-h5">
                <v-row class="d-flex justify-center">
                    <v-icon size="250">{{emailIcon}}</v-icon>
                </v-row>
                <v-row class="d-flex justify-center">
                    Please verify your email at: {{ $auth.user.email }}
                    <br />
                    to continue access.
                </v-row>
                <v-row class="d-flex justify-center pt-6">
                    <v-btn text outlined color="primary"
                        @click="$router.go()"
                    >
                        Email verified
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
      </div>
</template>

<script>
export default {
    data() {
        return {
            emailIcon: 'mdi-email',
        }
    },
    mounted(){
        this.animateInterval = setInterval(this.animateIcon, 1000);
    },
    methods: {
        animateIcon(){
            if (this.emailIcon == 'mdi-email') {
                this.emailIcon = 'mdi-email-open';
            }
            else {
                this.emailIcon = 'mdi-email';
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.animateInterval)
    }

}
</script>