<template>
    <v-app>
        <SideMenu v-if="$auth.isAuthenticated"/>

        <v-main>
            <router-view />
        </v-main>


        <!-- Modals -->

    </v-app>
</template>

<script>
import SideMenu from './components/SideMenu.vue';
import {version} from '../package.json';
import {mapState, mapActions} from 'vuex';

export default {
    name: "App",
    components: {
        SideMenu,
    },
    computed: {
        ...mapState([
            'configs'
        ]),
    },
    watch: {
        '$auth.loading': async function(val) {
            if (val == false) {
                let token = await this.$auth.getTokenSilently();
                this.initMatConfig(token);
            }
        }
    },
    mounted() {
        // if a redirect url exists for this environment's config,
        // then go there if current url contains appspot
        if (this.configs.redirect_url) {
            if (window.location.host.indexOf("appspot") > -1) {
                let pathname = window.location.pathname;
                window.location.replace(this.configs.redirect_url+pathname);
            }
        }

        // console.log("Access the global state store from the console by calling: fstore.xxx");
        window.fstore = document.getElementById('app').__vue__.$store.state;

        console.log("Version: ", version);
    },
    methods: {
        ...mapActions([
            'initMatConfig'
        ]),
        login() {
            this.$auth.loginWithRedirect();
        },
    }
};
</script>

<style lang="scss">
a {
    text-decoration: none;
}
.center-x {
    display: flex;
    justify-content: center;
}
.center-y {
    display: flex;
    align-items: center;
}

// dark themed data table
.dark-mode.v-data-table.theme--dark { 
    background-color: #363636; 
}

::-webkit-scrollbar { /* width */
  width: 8px;
}
::-webkit-scrollbar-track { /* track */
  background: rgb(36, 36, 36);
  border-radius: 3px;
}
::-webkit-scrollbar-thumb { /* handle */
  background: #888;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover { /* handle on hover */
  background: #555;
}

.no-underline.theme--light.v-text-field > .v-input__control > .v-input__slot:before,
.no-underline.theme--dark.v-text-field > .v-input__control > .v-input__slot:before,
.no-underline.theme--light.v-text-field > .v-input__control > .v-input__slot:after,
.no-underline.theme--dark.v-text-field > .v-input__control > .v-input__slot:after {
    border-style: none;
}
.no-underline.theme--light.v-text-field > .v-input__control > .v-text-field__details,
.no-underline.theme--dark.v-text-field > .v-input__control > .v-text-field__details {
    display: none;
}

.no-click {
    pointer-events: none;
}

</style>
