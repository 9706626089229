var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-3",attrs:{"color":"gray3"}},[_c('v-card-title',[_c('span',{staticClass:"overline"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.rawPrints.length==0,"color":"primary","fab":"","outlined":"","small":""},on:{"click":function($event){return _vm.saveToCSV()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-download")])],1)]}}])},[_c('span',[_vm._v("Download To CSV")])])],1),_c('v-data-table',{staticClass:"elevation-1 my-3 dark-mode",attrs:{"headers":_vm.headers,"items":_vm.printsForDisplay,"search":_vm.searchString,"sort-by":['id'],"sort-desc":[true],"loading":_vm.loading,"items-per-page":_vm.entriesPerPage,"multi-sort":"","dark":""},scopedSlots:_vm._u([{key:"item.successful",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.status[item.successful].color,"dark":""}},[_c('v-icon',[_vm._v(_vm._s(_vm.status[item.successful].icon))])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.clickPrint(item)}}},[_vm._v(" mdi-open-in-new ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }