<template>
    <div class="no-click">
         <v-sheet outlined color="grey darken-2" class="mt-3" rounded>
            <v-card outlined color="gray3">
                <v-card-title>
                    Basic Settings
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols=12 sm=6 md=4>
                            <v-text-field
                                v-if="configData.name"
                                label="Name"
                                :value="configData.name"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols=12 sm=6 md=4>
                            <v-text-field
                                v-if="configData.config_series"
                                label="Series"
                                :value="configData.config_series"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols=12 sm=6 md=4>
                            <v-text-field
                                v-if="configData.config_series == 3"
                                label="Material Type"
                                :value="configData.material_type"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>
        <v-sheet outlined color="grey darken-2" class="mt-3" rounded>
            <v-card outlined color="gray3">
                <v-card-title>
                    CKM Settings
                </v-card-title>
                <v-card-text>
                    <v-row dense v-if="configData.config_series == 3">
                        <v-col cols=12 sm=6 md=4>
                            <v-text-field
                                label="Ambient Heat Status"
                                :value="ambientHeatOff ? 'OFF' : 'ON'"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols=12
                            sm=6
                            md=4
                        >
                            <v-text-field v-if="!ambientHeatOff"
                                :label="materialParams
                                    .ckm_settings
                                    .amb_target_temp
                                    .label"
                                :value="concatParamValue(
                                    configData.amb_target_temp,
                                    materialParams
                                    .ckm_settings
                                    .amb_target_temp
                                    .units)"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col v-if="configData.config_series == 2" cols=12 sm=6 md=4>
                            <v-text-field 
                                label="CKM Mode"
                                :value="!ckmModeOn ? 'CKM OFF: reservoir only, no heat' : 'CKM LV: circulation'"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                        <v-col 
                            v-if="configData.config_series == 3
                                || ckmModeOn"
                            cols=12
                            sm=6
                            md=4
                        >
                            <v-text-field
                                label="CKM Speed"
                                :value="concatParamValue(
                                    configData.ckm_mode
                                        || configData.rsvr_temp_pump_lower,
                                    'rpm')"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols=12 sm=6 md=4>
                            <v-text-field
                                v-if="configData.rsvr_tgt_level 
                                    && configData.config_series == 3"
                                :label="materialParams
                                    .ckm_settings
                                    .rsvr_tgt_level
                                    .label"
                                :value="concatParamValue(
                                    configData.rsvr_tgt_level,
                                    materialParams
                                    .ckm_settings
                                    .rsvr_tgt_level
                                    .units)"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols=12 sm=6 md=4>
                            <v-text-field
                                label="CKM Heat Status"
                                :value="ckmHeatOff ? 'OFF' : 'ON' "
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                        <v-col 
                            v-for="(value, setting) in filterObject(
                                materialParams.ckm_settings)"
                            cols=12
                            sm=6
                            md=4
                            :key="setting"
                        >
                            <v-text-field
                                v-if="!ckmHeatOff"
                                :label="value.label"
                                :value="concatParamValue(
                                    configData[setting], 
                                    value.units)"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>
        <v-sheet outlined color="grey darken-2" class="mt-3" rounded>
            <v-card outlined color="gray3">
                <v-card-title>
                    Peel Settings
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col
                            v-for="(value, setting)
                                in materialParams.peel_settings"
                            :key="setting"
                            cols=12
                            sm=6
                            md=3
                        >
                            <v-text-field
                                :label="value.label"
                                :value="concatParamValue(
                                    configData[setting],
                                    value.units)"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>
        <v-sheet outlined color="grey darken-2" class="mt-3" rounded>
            <v-card outlined color="gray3">
                <v-card-title>
                    Plunge Settings
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                       <v-col
                            v-for="(value, setting)
                                in materialParams.plunge_settings"
                            :key="setting"
                            cols=12
                            sm=6
                            md=3
                        >
                            <v-text-field
                                :label="value.label"
                                :value="concatParamValue(
                                    configData[setting],
                                    value.units)"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>
        <v-sheet outlined color="grey darken-2" class="mt-3" rounded>
            <v-card outlined color="gray3">
                <v-card-title>
                    UV Settings
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                       <v-col
                            v-for="(value, setting)
                                in materialParams.uv_settings"
                            :key="setting"
                            cols=12
                            sm=6
                            md=3
                        >
                            <v-text-field
                                :label="value.label"
                                :value="concatParamValue(
                                    configData[setting],
                                    value.units)"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>
        <v-sheet
            v-if="internalUse && configData.config_series == 3"
            outlined
            color="grey darken-2"
            class="mt-3"
            rounded
        >
            <v-card outlined color="gray3">
                <v-card-title>
                    <v-col class="px-0"> Default Settings </v-col>
                    <v-col 
                        class="overline primary--text"
                        align="right">
                        Internal Use
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col v-for="(value, setting) in defaultValuesMap"
                            cols=12 sm=6 md=3 :key="setting"
                        >
                            <v-text-field
                                v-if="!matConfigDefaultValueExceptions.includes(setting)"
                                :label="setting=='donotoverridegcode'
                                    ? 'doNotOverrideGCode' : setting"
                                :value="configCreationDisplay && configData[setting]==null ? concatParamValue(defaultValuesMap[setting]) : concatParamValue(configData[setting])"
                                :success="configCreationDisplay && configData[setting]==null ? false : configData[setting] != defaultValuesMap[setting]"
                                class="no-underline"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>
        <v-sheet
            v-if="internalUse && configData.config_series == 3"
            outlined
            color="grey darken-2"
            class="mt-3"
            rounded
        >
            <v-card outlined color="gray3">
                <v-card-title>
                    <v-col class="px-0"> Override Configs </v-col>
                    <v-col
                        class="overline primary--text"
                        align="right"
                    >
                        Internal Use
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col v-for="(value, setting) in overrideConfigs"
                            cols=12 sm=6 md=3 :key="setting"
                        >
                            <v-text-field
                                :label="setting"
                                :value="concatParamValue(
                                    overrideConfigs[setting],
                                    null)"
                                class="no-underline"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>
        <v-sheet
            v-if="internalUse && configData.config_series == 3"
            outlined
            color="grey darken-2"
            class="mt-3"
            rounded
        >
            <v-card outlined color="gray3">
                <v-card-title>
                    <v-col class="px-0"> Print Quality Configs </v-col>
                    <v-col
                        class="overline primary--text"
                        align="right"
                    > Internal Use
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col v-if="configData.material_scale_factor!=undefined" cols=12 sm=6 md=3>
                            <v-text-field
                                label="Material Scale Factor"
                                :value="configData.material_scale_factor"
                                class="no-underline"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col v-for="(value, setting) in printQualityConfigs"
                            cols=12 sm=6 md=3 :key="setting"
                        >
                            <v-text-field
                                v-if="setting != 'moduleList' && typeof setting == 'string'"
                                :label="setting"
                                :value="concatParamValue(
                                    printQualityConfigs[setting],
                                    null)"
                                class="no-underline"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col v-if="printQualityConfigs && printQualityConfigs.moduleList" cols=12>
                            <v-text-field
                                label="moduleList"
                                class="no-underline"
                            >
                            </v-text-field>
                            <pre
                                id=json
                                class="brightText--text"
                            >{{ printQualityConfigs.moduleList }}
                            </pre>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-sheet>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    props: [
        'configData',
        'configCreationDisplay'
    ],
    computed: {
        ...mapState([
            'matParams',
            'matParams3x',
            'baseURL',
            'iam',
            'internalUse',
            'matConfigComms',
            'matConfigDefaultValueExceptions',
        ]),
        ckmHeatOff() {
            if(this.configData.config_series == 2) {
                return (this.configData.ckm_mode == 0 ||
                        (this.configData['resin_temp'] == this.configData['max_wall_temp']));
            }
            else if (this.configData.config_series == 3) {
                return (this.configData.ckm_heat_disable == true);
            }
            return false;
        }, 
        ambientHeatOff() {
            return (!this.configData.amb_heat_enable);
        },
        ckmModeOn(){
            return (this.configData.ckm_mode && this.configData.ckm_mode !=0);
        },
        materialParams(){
            if (this.configData.config_series == 2) {
                return this.matParams;
            }
            else {
                return this.matParams3x;
            }
        },
        defaultValuesMap() {
            return this.matConfigComms.defaultSettingsMap;
        },
        overrideConfigs() {
            // for already created configs
            if (this.configData.config_override
                && typeof this.configData.config_override == 'string') {
                try {
                    return JSON.parse(this.configData.config_override);
                }
                catch(e) {
                    console.log("Invalid override configs json.");
                }
            }
            // for creation display
            else {
                return this.configData.config_override;
            }
        },
        printQualityConfigs() {
            if (this.configData.print_quality) {
                try {
                    let parsed = JSON.parse(this.configData.print_quality);
                    return parsed;
                }
                catch(e) {
                    console.log("Invalid print quality configs json.");
                }
            }
        }
    },
    methods: {
        concatParamValue(value, units) {
            let temp = 'N/A';
            if (value == null ) {
                return temp;
            }
            return temp = value + (units ? ' ' + units : '');
        },
        // Filter out objects with a local variable and
        // special display parameter
        filterObject(objectList) {
            let filtered = Object.entries(objectList).filter(
                ([key, value]) => !value.hasLocalVar && !value.specialDisplay);
            return Object.fromEntries(filtered);
        }
    }
}
</script>