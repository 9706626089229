<template>
    <v-card
        class="my-5 grey darken-3"
        style="border: 0.5px solid grey !important;"
    >
        <v-card-title class="pt-1">
            <span class="overline">Result Edit History</span>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table class="grey darken-3"
            :headers="headers"
            :items="printEditHistoryList"
            item-key="endPrint_createdAt"
            :loading="loadingBarOn" 
            :hide-default-footer="false"
        > 
            <template v-slot:[`item.successText`]="{ item }">
                {{status[item.successText].text}}
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
import {mapState} from 'vuex';
import PrintEditForm from './PrintEditForm.vue';

export default {
    props: {
        printEditHistoryList: {
            type: Array,
            required: true
        },
        loadingBarOn: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {
        PrintEditForm,
    },
    data() {
        return {
            headers: [
                {
                    text: 'Date',
                    value: 'endPrint_createdAt'
                },
                {
                    text: 'Success/Fail',
                    value: 'successText',
                    sortable: false
                },
                {
                    text: 'Failure Type',
                    value: 'failure_type_desc',
                    sortable: false
                },
                {
                    text: 'Failure Reason',
                    value: 'fail_desc',
                    sortable: false
                },
                { text: 'User', value: 'userName' },
            ],
        }
    },
    computed: {
        ...mapState([
            'status',
        ]),
    },
    mounted() {
    },
    methods: {
    }
}
</script>
