<template>
    <v-card v-if="selectedPrint" class="ma-0 pa-10">
        <v-container class="pa-0 darkgray2" id="page-content" style="max-width:1400px">
            <v-card flat class="mx-0 px-0 mb-10" color="darkgray2"
                style="width:100%">
                <v-toolbar
                    dense
                    flat
                    color="primaryDark2 darken-1"
                    dark
                    height="85px"
                >
                    <v-toolbar-title class="center-y toolbar-title">
                        <span
                            style="text-transform: none;
                                font-size: 38px; letter-spacing: .01em;"
                        >
                            Print {{this.selectedPrint.id}}
                        </span>
                        <div v-if="printOutsideOrg" class="ml-8">
                            <v-icon large>mdi-account-tie</v-icon>
                            <span class="overline">Customer Data</span>
                        </div>
                    </v-toolbar-title>
                    <v-spacer/>
                    <v-chip
                        :color="status[this.selectedPrint.successText].color"
                        class="elevation-4"
                    >
                        <span class="title">
                            {{status[this.selectedPrint.successText].text}}
                        </span>
                    </v-chip>
                </v-toolbar>
                <v-card-text class="pt-3 pb-0">
                    <v-row class="mx-0 center-x center-y">
                        <v-col cols=5 xs=12 sm=6 md=5 lg=5>
                            <v-text-field
                                v-model="this.selectedPrint.build_name_full"
                                label="Build Name"
                                prepend-icon="mdi-tag-text-outline"
                                readonly class="no-underline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols=5 xs=12 sm=6 md=4 lg=4>
                            <v-text-field
                                :value="this.selectedPrint.printer + ' @ '
                                    + this.selectedPrint.location"
                                label="Printer"
                                prepend-icon="mdi-printer-3d"
                                readonly class="no-underline"
                            ></v-text-field>
                        </v-col>
                        <v-col cols=5 xs=12 sm=6 md=3 lg=3 class="center-x center-y">
                            <v-text-field
                                v-model="this.selectedPrint.printed_at"
                                label="Printed At"
                                prepend-icon="mdi-calendar"
                                readonly class="no-underline center-x center-y"
                                style="text-align: center !important"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <DescriptionDisplay
                :selectedItem="selectedPrint"
                itemType="print"
                :disableEdit="printOutsideOrg"
            />
            <AttachmentDisplay
                :itemType="'print'"
                :selectedItem="selectedPrint"
                :options="{
                    disableAdd: printOutsideOrg,
                    disableDelete: printOutsideOrg
                }"
            />
            <v-card class="grey darken-3 mx-6 mb-10">
                <v-toolbar
                    dense
                    color="primaryDark2 lighten-1"
                    height="38px"
                >
                    <v-toolbar-title class="toolbar-title">
                        Details
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="font-weight-medium my-3 no-click">
                    <v-row v-if="selectedPrint.job_id != null">
                        <v-col cols=2 class="center-x center-y">
                            <span class="font-weight-bold body-1">Job Info</span>
                        </v-col>
                        <v-col cols=10>
                            <v-row>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="selectedPrint.job_id || 'N/A'"
                                        label="Job ID"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="jobInfo.name || 'N/A'"
                                        label="Job Name"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="jobInfo.printFile_name || 'N/A'"
                                        label="3DF Name"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="jobInfo.material_config_name || 'N/A'"
                                        label="Material Config"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="jobInfo.mat_type || 'N/A'"
                                        label="Material Type"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="jobInfo.magnet_mode || 'N/A'"
                                        label="Alignment"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="jobInfo.created_by_name || 'N/A'"
                                        label="Created By"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider 
                        dark
                        v-if="selectedPrint.job_id != null" 
                        class="mt-4 mb-8" 
                        style="width: 100%; margin: auto">
                    </v-divider>
                    <v-row>
                        <v-col cols=2 class="center-x center-y">
                            <span class="font-weight-bold body-1">Versions</span>
                        </v-col>
                        <v-col cols=10>
                            <v-row>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="selectedPrint.printer_host_version || 'N/A'"
                                        label="FluxHost Version"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.fw_print_version || 'N/A'"
                                        label="Firmware Printer Version"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.fw_flux_version || 'N/A'"
                                        label="Firmware Flux Version"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.compass_version || 'N/A'"
                                        label="Compass Version"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider dark class="mt-4 mb-8" style="width: 100%; margin: auto"></v-divider>
                    <v-row>
                        <v-col cols=2 class="center-x center-y">
                            <span class="font-weight-bold body-1">Print Info</span>
                        </v-col>
                        <v-col cols=10>
                            <v-row>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.ckm_mode_string || 'N/A'"
                                        label="CKM Mode"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.total_layers || 'N/A'"
                                        label="Total Layers"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="(this.selectedPrint.volume/1000).toFixed(2) + 'mL' || 'N/A'"
                                        label="Volume"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.duration_hm || 'N/A'"
                                        label="Duration"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.est_duration || 'N/A'"
                                        label="Estimated Print Time"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.load_at_zero
                                            ? this.selectedPrint.load_at_zero + ' N'
                                            : 'N/A'"
                                        label="Load at Position Zero"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.load_at_confirm
                                            ? this.selectedPrint.load_at_confirm + ' N'
                                            : 'N/A'"
                                        label="Load at User Confirm"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-divider dark class="mt-4 mb-8" style="width: 100%; margin: auto"></v-divider>
                    <v-row class="mb-2">
                        <v-col cols=2 class="center-x center-y">
                            <span class="font-weight-bold body-1">Pre-Print</span>
                        </v-col>
                        <v-col cols=10>
                            <v-row>
                                <v-col xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.number_pump_tube_cycles || 'N/A'"
                                        label="Total Transfer Tube Cycles"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.number_pump_cycles || 'N/A'"
                                        label="Total Circulation Tube Cycles"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=2 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.film_layer_count
                                            ? this.selectedPrint.film_layer_count + ' layers'
                                            : 'N/A'"
                                        label="Film Usage at Start of Print"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols=12 xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.time_since_last_print || 'N/A'"
                                        label="Time Since Last Print"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols=2 class="center-x center-y">
                            <span class="font-weight-bold body-1">Post-Print</span>
                        </v-col>
                        <v-col cols=10>
                            <v-row>
                                <v-col xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.tube_pump_cycles_in_print 
                                            || 'N/A'"
                                        label="Transfer Tube Cycles During Print"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                                <v-col xs=12 sm=6 md=3 lg=3 class="py-0">
                                    <v-text-field
                                        :value="this.selectedPrint.ckm_pump_cycles_in_print 
                                            || 'N/A'"
                                        label="Circulation Tube Cycles During Print"
                                        readonly class="no-underline"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <div
                v-if="(this.selectedPrint.material_config
                    || this.selectedPrint.material_config_3x)
                    && iam.fluxdev"
            >
                <v-card color="grey darken-3" class="mx-6">
                    <v-toolbar
                        dense
                        color="primaryDark2 lighten-1"
                        style="border-radius: 3pt"
                        dark
                        height="38px"
                    >
                        <v-toolbar-title class="toolbar-title">
                            Cloud Material Configs
                        </v-toolbar-title>
                    </v-toolbar>
                    <v-expansion-panels flat class="py-1">
                        <v-expansion-panel>
                            <v-progress-linear indeterminate v-if="materialConfigLoading"></v-progress-linear>
                            <v-expansion-panel-header color="grey darken-3">
                                {{this.selectedPrint.material_config || this.selectedPrint.material_config_3x}}
                                <span v-if="printOutsideOrg" class="ml-2 grey--text">
                                    |
                                    <v-icon class="ml-1" color="grey">mdi-account-tie</v-icon>
                                    <span class="overline">Customer Data</span>
                                </span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content color="grey darken-3">
                                <MaterialConfigDisplay 
                                    v-if="!materialConfigLoading"
                                    :configData="materialConfigData"
                                    :configCreationDisplay="false"
                                />
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </div>
            <div v-else>
                <v-subheader class="pl-8 mt-8">
                    Local Material Profile
                </v-subheader>
                <span class="ml-10 body-1 white--text">
                    {{selectedPrint.local_mat_config_name || 'N/A'}}
                </span>
            </div>
            <PrintResultDisplay
                @update-print-history="getPrintDetails()"
                :printEditHistoryList="selectedPrintHistory"
                :selectedPrint="selectedPrint"
                :disableEdit="printOutsideOrg"
            />
            <v-row class="center-x">
                <div v-show="showLoadCell" id="load-cell-plot"></div>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import {mapState} from 'vuex';
// import {getStorageFile} from '../assets/js/getStorageFile';
import AttachmentDisplay from '../components/AttachmentDisplay';
import MaterialConfigDisplay from '../components/MaterialConfigDisplay';
import DescriptionDisplay from '../components/DescriptionDisplay';
import PrintResultDisplay from '../components/PrintResultDisplay';

export default {
    props: [
        'printId',
        'selectedPrintProp'
    ],
    components: {
        MaterialConfigDisplay,
        DescriptionDisplay,
        AttachmentDisplay,
        PrintResultDisplay,
    },
    data: function() {
        return {
            selectedPrint: undefined,
            printOutsideOrg: false,
            materialConfigData: {},
            materialConfigLoading: false,
            showLoadCell: false,
            loadCellLoading: false,
            selectedPrintHistory: [],
            jobInfo: {},
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'dialogs',
            'status',
            'iam',
        ]),
    },
    mounted() {
        if (this.selectedPrintProp) {
            this.selectedPrint = this.selectedPrintProp; // TODO
        }

        // TODO: (see TODO in PrintList.vue) the below block will always get executed bc the
        // selectedPrintProp and selectedPrint variables are always undefined
        if (!this.selectedPrint) {
            this.getPrintDetails();
        }
    },
    methods: {
        async getPrintDetails() {
            let t = await this.$auth.getTokenSilently();
            console.log("Requesting /database/customSearch");

            let params = {
                param: {
                    printId: this.printId,
                    order: ['end_print.created_at'],
                    uniquePrintID: false
                }
            }
            this.$http.get(this.baseURL+"/database/customSearch", {
                headers: { Authorization: `Bearer ${t}` }, params})
            .then((response) => {
                console.log("Response for /database/customSearch: ",
                    response.data.body);
                this.selectedPrint = response.data.body[0];
                this.selectedPrintHistory = response.data.body;

                this.printOutsideOrg 
                    = (this.selectedPrint.org_id != this.iam.org_id);
                this.selectedPrint.successText
                    = this.selectedPrint.successful == true?
                        'success' : (this.selectedPrint.successful == false ?
                        'failed' : 'unknown')

                this.selectedPrint['sized_fiber'] = 
                    (this.selectedPrint.sizing_fiber_batch == undefined) ? this.selectedPrint.master_sizing_fiber_batch : this.selectedPrint.sizing_fiber_batch;

                this.selectedPrint.build_name_full = this.selectedPrint.build_name;
                if (this.selectedPrint.build_name && this.selectedPrint.build_name.length > 20) {
                    this.selectedPrint.build_name = this.selectedPrint.build_name.slice(0, 20) + "...";
                }

                this.selectedPrint.printed_at = new Date(this.selectedPrint.printed_at).toLocaleString();
                this.selectedPrint.engineer = this.selectedPrint.engineer == undefined ? 'N/A' : this.selectedPrint.engineer;
                this.selectedPrint.customer = this.selectedPrint.customer ? this.selectedPrint.customer : 'N/A';

                let lastLayer = this.selectedPrint.last_layer ? this.selectedPrint.last_layer : 0
                this.selectedPrint.layersComplete = lastLayer + "/" + this.selectedPrint.total_layers;
                this.selectedPrint.percentComplete = Math.round(lastLayer/this.selectedPrint.total_layers*100);
                this.selectedPrint.duration = (this.selectedPrint.end_time == undefined ? 'N/A' :
                    ((new Date(this.selectedPrint.end_time) - new Date(this.selectedPrint.printed_at))/3600000).toFixed(1) + " hrs")

                this.selectedPrint.est_duration = this.msToHM(this.selectedPrint.est_duration);
                this.selectedPrint.duration_hm = (this.selectedPrint.end_time == undefined ? 'N/A' :
                    (this.msToHM(new Date(this.selectedPrint.end_time) - new Date(this.selectedPrint.printed_at))));
                this.selectedPrint.end_time = new Date(this.selectedPrint.end_time).toLocaleString(); // format for csv

                 let modesBin={
                            1:"Full",
                            2:"Low Volume",
                            3:"Reservoir Only",
                        };
                this.selectedPrint.ckm_mode_string 
                    = modesBin[this.selectedPrint.ckm_mode];

                if (this.selectedPrint.ckm_mode_string == undefined) {
                    this.selectedPrint.ckm_mode_string = 'N/A';
                }

                // only show cloud material config if user is fluxdev
                if ((this.selectedPrint.material_config || this.selectedPrint.material_config_3x)
                    && this.iam.fluxdev) {
                    this.getMaterialConfigData();
                }

                if (this.selectedPrint.job_id != null) {
                    this.getJobInfo(this.selectedPrint.job_id)
                }

                this.processSelectedPrintHistory();
            });
        },
        // iterates through each print edit and modifies values to display in 
        // print result edit table
        processSelectedPrintHistory() {
            this.selectedPrintHistory.forEach((printEdit) => {

                if (printEdit.successful == true) {
                    printEdit.successText = 'success'
                }
                else if (printEdit.successful == false) {
                    printEdit.successText = 'failed'
                }
                else if (printEdit.successful == undefined) {
                    printEdit.successText = 'unknown'
                }

                if (printEdit.first_name && printEdit.last_name) {
                    printEdit.userName 
                        = printEdit.first_name + " " + printEdit.last_name;
                }
                else {
                    printEdit.userName = "";
                }

                if (printEdit.endPrint_createdAt){
                    printEdit.endPrint_createdAt
                        = new Date(printEdit.endPrint_createdAt)
                        .toLocaleString();
                }
                else {
                    printEdit.endPrint_createdAt = "N/A";
                }
                printEdit.fail_desc = this.processFailureReason(printEdit);
                printEdit.failure_display = this.processFailureDisplay(printEdit);
            })
        },
        processFailureReason(print) {
            if (print.hardfault_desc) {
                return "Hard Faulted -- "
                + print.hardfault_desc.slice(0, -1);
            }
            else if (print.fail_desc) {
                return print.fail_desc;
            }
            else {
                return 'N/A';
            }
        },
        processFailureDisplay(print) {
            if (print.hardfault_desc) {
                return print.fail_desc;
            }
            if (print.fail_desc != 'N/A' && print.fail_desc != undefined) {
                return `${print.failure_type_desc}: ${print.fail_desc}`;
            }
            return 'N/A';
        },
        async getJobInfo(jobId) {
            let t = await this.$auth.getTokenSilently();
             let params = {
                headers: { Authorization: `Bearer ${t}` },
                params: {
                  param: jobId
                }
            }

            try {
                let response = await this.$http.get(
                    this.baseURL + '/database/jobByID', 
                    params)
                this.jobInfo = response.data.body[0];
                this.$set(
                    this.jobInfo,
                    'created_by_name',
                    this.jobInfo.first_name + ' ' + this.jobInfo.last_name);
                this.$set(
                    this.jobInfo,
                    'material_config_name',
                    this.jobInfo.mat_config_name_2x 
                        || this.jobInfo.mat_config_name_3x);
            }
            catch(e) {
                console.log("Failed to get job info: ", e);
            }
        },
        async getMaterialConfigData() {
            if (Object.keys(this.materialConfigData).length != 0) {
                return; // already got material config data
            }

            let url, series;
            let t = await this.$auth.getTokenSilently();

            let params = {
                headers: { Authorization: `Bearer ${t}` }, 
                params: {}
            }

            if (this.selectedPrint.material_config) { // 2x config
                series = '2';
                params["params"]["param"] = this.selectedPrint.material_config;
                url = '/database/materialConfigByName';
            }
            else { // 3x config
                series = '3';
                params["params"]["param"] = this.selectedPrint.material_config_3x;
                url = '/database/materialConfig3xByName';
            }

            this.materialConfigLoading = true

            console.log("Requesting /database/materialConfigByName");

            this.$http.get(this.baseURL+url, params)
            .then((response) => {
                console.log("Got response from ", url, ": ", response);
                this.materialConfigData = response.data.body[0];
                this.materialConfigData.config_series = series;
                this.materialConfigData.created_at = new Date(this.materialConfigData.created_at).toLocaleString();
                this.materialConfigLoading = false;
            })
            .catch((e) =>{
                console.log("Failed to get material config: ", e);
            })
        },
        msToHM(duration) {
            let minutes = Math.floor((duration / (1000 * 60)) % 60),
                hours = Math.floor((duration / (1000 * 60 * 60)));

            return hours + "h " + minutes + "m";
        },
        downloadFile(fileName, type) {
            let pathDetails = {
                printerName: this.selectedPrint.printer,
                year: new Date(this.selectedPrint.printed_at).getFullYear(),
                type: type,
                fileName: fileName
            };

            // getStorageFile(this.$user.getToken(), this.baseURL, pathDetails)
            // .then((response) => {
            //     console.log("Got response from /database/downloadFile:", response);
            //     // open signed URL in new tab
            //     window.open(response.data.body, '_blank');
            // })
        },
        plotLoadCell(fileName) {
            this.loadCellLoading = true;

            let pathParts = [
                this.selectedPrint.printer,
                new Date(this.selectedPrint.printed_at).getFullYear(),
                fileName
            ];
            let params = {
                token: this.$user.getToken(),
                param: pathParts.join("/")
            }
    
            this.$http.get(this.baseURL+'/storage/getLoadCellData', {params})
            .then((response) => {
                console.log("Got response from /storage/getLoadCellData:", response);
                let data = response.data.body;

                let trace1 = {
                    x: data.x,
                    y: data.load,
                    name: 'Load',
                    type: 'scatter'
                };

                let trace2 = {
                    x: data.x,
                    y: data.position,
                    name: 'Position',
                    yaxis: 'y2',
                    type: 'scatter'
                };

                let traces = [trace1, trace2];

                let layout = {
                    title: 'Load Cell Data',
                    yaxis: {title: 'Load (N)'},
                    yaxis2: {
                        title: 'Position (um)',
                        overlaying: 'y',
                        side: 'right'
                    },
                    xaxis: {title: 'Time (s)'},
                    autosize: false,
                    width: document.getElementById('page-content').offsetWidth
                };

                this.loadCellLoading = false;
                Plotly.newPlot('load-cell-plot', traces, layout);

                this.showLoadCell = true;

            },
            (error) => {
                this.loadCellLoading = false;
            });
        }
    }
}
</script>

<style>

</style>