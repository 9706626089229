<template>
    <v-card
        color="grey darken-3"
        align="center"
        class="mb-10 mx-6"
    >
        <v-toolbar
            dense
            color="primaryDark2 lighten-1"
            dark
            height="38px"
        >
            <v-toolbar-title class="toolbar-title">Attachments</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-btn
                            @click="handleFileImport"
                            :disabled="options.disableAdd"
                            small
                            color="white"
                            rounded
                        >
                            <v-icon color="primary">mdi-plus</v-icon>
                        </v-btn>
                    </div>
                </template>
                <span>
                    {{ options.disableAdd ? 'Disabled for Customer Print' : 'Add Attachment' }}
                </span>
            </v-tooltip>
        </v-toolbar>
        <input 
            ref="uploader"
            class="d-none"
            type="file"
            @change="saveAttachment"
        >
        <v-row class="ma-2">
            <v-slide-group
                show-arrows
            >
                <v-slide-item
                    v-for="attachment in retrievedAttachments"
                    :key="attachment.id"
                    v-slot="{ active, toggle }"
                    class="my-4"
                >
                    <v-card
                        color="blue-grey darken-1"
                        class="mx-2 d-flex align-center justify-center"
                        width="225"
                        height="225"
                        hover
                        @click="toggle"
                    >
                        <v-img 
                            v-if="imageTypes.includes(attachment.filetype)"
                            :src="attachment.fileUrl"
                            :aspect-ratio="1"
                        >
                        </v-img>
                        <div v-else>
                            <v-row 
                                justify="center"
                                align="center"
                                class="body-1 mx-2 mb-3 text-wrap"
                            >
                                <span style="max-width: 200px;">
                                   {{attachment.name}}
                                </span>
                            </v-row>
                            <v-icon color="white" x-large>
                                mdi-file-document-outline
                            </v-icon>
                        </div>
                        <v-overlay
                            :value="active"
                            opacity=".8"
                            absolute
                        >
                            <v-card-title class="body-1 center-x">
                                {{attachment.name}}
                            </v-card-title>
                            <v-row class="mt-10">
                                <v-col cols="6">
                                    <v-btn text :disabled="options.disableDelete">
                                        <v-icon
                                            color="grey lighten-2"
                                            size="30"
                                            @click="removeAttachment(
                                                attachment.id)"
                                        > mdi-trash-can-outline 
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn :disabled="!attachment.fileUrl" text>
                                        <v-icon
                                            color="primary"
                                            size="30"
                                            @click="downloadAttachment(
                                                attachment)"
                                        > mdi-download
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-overlay>
                    </v-card>
                </v-slide-item>
            </v-slide-group>
            <v-card v-if="!retrievedAttachments
                || retrievedAttachments.length == 0"
                flat
                color="transparent"
                class="d-flex align-center justify-center"
                align="center"
                height="150"
                width="100%"
            >
                <span class="overline grey--text">No Attachments</span>
            </v-card>
        </v-row>
    </v-card>
</template>

<script >

import {mapState} from 'vuex';

export default {
    props: {
        itemType: {
            type: String,
            required: true
        },
        selectedItem: {
            type: Object,
            required: true
        },
        options: {
            type: Object,
            required: false,
            default() {
                return {
                    disableAdd: false,
                    disableDelete: false,
                }
            }
        }
    },
    components: {
    },
    data() {
      return {
        imageTypes: ['jpeg', 'jpg', 'png', 'raw', 'gif', 'tif', 'tiff'],
        retrievedAttachments: [],
      }
    },
    computed: {
        ...mapState([
            'baseURL',
        ]),
    },
    async mounted() {
        await this.getItemAttachments();
    },
    methods: {
        async saveAttachment(selectedFile) {
            let t = await this.$auth.getTokenSilently();
            let file = selectedFile.target.files[0];
            let formData = new FormData();
            formData.append("userToken", t);
            formData.append("item_id", this.selectedItem.id);
            formData.append("item_type", this.itemType);
            formData.append("file", file);

            let headers = {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${t}`
            }

            try {
                await this.$http.post(
                    this.baseURL+'/database/addAttachment',
                    formData,
                    { headers }
                );
                console.log("Got response for /database/addAttachment.");
                await this.getItemAttachments();
            }
            catch(error) {
                console.log("Error for /database/addAttachment: ", error);
            }
        },
        async getItemAttachments() {
            let t = await this.$auth.getTokenSilently();
            let params = {
                headers: {Authorization: `Bearer ${t}`},
                params: { 
                    param: {
                        item_id: this.selectedItem.id,
                        item_type: this.itemType
                    }
                }
            }
            console.log("Requesting /database/attachmentsByItem");
            try {
                let response = await this.$http.get(
                    this.baseURL+'/database/attachmentsByItem', params);
                this.retrievedAttachments = response.data.body;
                // attachments are sorted by created_at desc, so this sorts them
                // in date ascending order 
                this.retrievedAttachments.reverse();
                console.log("Response for /database/attachmentsByItem: ", 
                    response.data.body);
                this.setAllAttachmentExtensions();
            }
            catch(e){
                console.log("Error getting print attachments: ", e);
            }
        },
        async setAllAttachmentExtensions() {
            this.retrievedAttachments
                .forEach(this.setAttachmentExtension);
        },
        // sets extension for each file
        setAttachmentExtension(item, index) {
            try {
                let filename
                    = this.retrievedAttachments[index].name.split('.');
                let extension = filename[filename.length-1].toLowerCase();
                this.$set(
                    this.retrievedAttachments[index],
                    'filetype',
                    extension)
            }
            catch(e) {
                console.error(e)
            }
        },
        async downloadAttachment(attachment) {
            if (attachment.fileUrl) {
                window.open(attachment.fileUrl, '_blank');
            }
            else {
                console.log(attachment.name, " not found.")
            }
        },
        // retires attachment based on attachment id 
        async removeAttachment(attachment_id) {
            let t = await this.$auth.getTokenSilently();

            let params = {
                id: attachment_id,
                retired: true
            }
            try {
                let response = await this.$http.post(
                    this.baseURL+'/database/retireAttachment',
                    params, {
                        headers: {
                            Authorization: `Bearer ${t}`
                        }
                    })
                console.log(
                    "Got response for /database/retireAttachment: ",
                    response);
                await this.getItemAttachments();
            }
            catch(error) {
                console.log("Error for /database/retireAttachment: ", error)
            }
        },
        /** file browser button from 
        * https://ourcodeworld.com/articles/read/1424/ \ 
        * how-to-use-a-button-as-a-file-uploader-with-vuetify-in-vuejs 
        */
        handleFileImport() {
            window.addEventListener('focus', () => {
            }, { once: true });
            
            // Trigger click on the FileInput
            this.$refs.uploader.click();
        },
    }
}
</script>

<style scoped>
</style>