<template>
    <v-dialog
        v-model="dialogs.materialConfigForm.open"
        persistent overlay-opacity="0.85" max-width="950px"
    >
        <template v-slot:activator="{ on }">
            <v-btn color="primary" fab outlined small dark 
                v-on="on" class="ma-1">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-card :key="rerender">
            <v-card-title>
                <span> Add 
                    <span v-if="configType && configType == 2" > 
                        2-Series 
                    </span>
                    <span v-else-if="configType && configType == 3" > 
                        3-Series 
                    </span>
                    Material Configuration
                </span>

                <v-spacer />
                <v-btn 
                    @click="closeModal()"
                    icon color="primary"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>

                <v-stepper non-linear v-model="formStepper">
                    <v-stepper-header>
                        <v-stepper-step
                            editable color="green"
                            :rules="[() => basicForm 
                                || formStepper < widgetSteps.basic.step]"
                            :complete="formStepper > widgetSteps.basic.step"
                            :step="widgetSteps.basic.step"
                        >
                            {{ widgetSteps.basic.name }}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            editable color="green"
                            :rules="[() => ckmForm 
                                || formStepper < widgetSteps.ckm.step]"
                            :complete="formStepper > widgetSteps.ckm.step"
                            :step="widgetSteps.ckm.step"
                            :disabled="configType"
                        >
                            {{ widgetSteps.ckm.name }}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            editable color="green"
                            :rules="[() => peelForm 
                                || formStepper < widgetSteps.peel.step]"
                            :complete="formStepper > widgetSteps.peel.step"
                            :step="widgetSteps.peel.step"
                        >
                            {{ widgetSteps.peel.name }}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step 
                            editable color="green"
                            :rules="[() => plungeForm 
                                || formStepper < widgetSteps.plunge.step]"
                            :complete="formStepper > widgetSteps.plunge.step"
                            :step="widgetSteps.plunge.step"
                        >
                            {{ widgetSteps.plunge.name }}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step 
                            editable color="green"
                            :rules="[() => uvForm 
                                || formStepper < widgetSteps.uv.step]"
                            :complete="formStepper > widgetSteps.uv.step"
                            :step="widgetSteps.uv.step"
                        >
                            {{ widgetSteps.uv.name }}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step v-if="internalUse && configType==3"
                            editable color="green"
                            :rules="[() => defaultForm 
                                || formStepper < widgetSteps.default.step]"
                            :complete="formStepper > widgetSteps.default.step"
                            :step="widgetSteps.default.step"
                        >
                            {{ widgetSteps.default.name }}
                        </v-stepper-step>

                        <v-divider v-if="internalUse && configType==3"></v-divider>

                        <v-stepper-step v-if="internalUse && configType==3"
                            editable color="green"
                            :rules="[() => overrideForm
                                || formStepper < widgetSteps.override.step]"
                            :complete="formStepper > widgetSteps.override.step"
                            :step="widgetSteps.override.step"
                        >
                            {{ widgetSteps.override.name }}
                        </v-stepper-step>

                        <v-divider v-if="internalUse && configType==3"></v-divider>

                        <v-stepper-step v-if="internalUse && configType==3"
                            editable color="green"
                            :rules="[() => printQualityForm
                                || formStepper < widgetSteps.printQuality.step]"
                            :complete="formStepper > widgetSteps.printQuality.step"
                            :step="widgetSteps.printQuality.step"
                        >
                            {{ widgetSteps.printQuality.name }}
                        </v-stepper-step>

                        <v-divider v-if="internalUse && configType==3"></v-divider>

                        <v-stepper-step 
                            editable color="green"
                            :step="widgetSteps.review.step"
                        >
                            {{ widgetSteps.review.name }}
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items ref="formParent" v-model="formParent">
                        <v-stepper-content class="pa-0" 
                            :step="widgetSteps.basic.step">
                            <v-card 
                                class="d-flex flex-column outer-widget-card">
                                <v-card-title 
                                    class="center-x subtitle-1 font-weight-light">
                                    Basic Settings
                                </v-card-title>
                                <v-card-text>
                                    <v-form v-model="basicForm"
                                        autocomplete="off" ref="basicForm">
                                         <v-row v-if="!onlyOneSeriesAvail" class="center-x">
                                            <v-col
                                                class="center-x"
                                                cols="12"
                                                sm="6"
                                            >
                                                <v-btn-toggle 
                                                    @change="handleConfigType"
                                                    v-model="configType" 
                                                    mandatory color="primary" 
                                                    rounded
                                                >
                                                    <v-btn v-if="show2x" :value="2">
                                                        2-Series
                                                    </v-btn>
                                                    <v-btn v-if="show3x" :value="3">
                                                        3-Series
                                                    </v-btn>
                                                </v-btn-toggle>
                                            </v-col>
                                        </v-row>
                                        <v-row class="center-x">
                                            <v-col cols="12" sm="6">
                                                <v-text-field
                                                    v-model="basicSettings.name"
                                                    label="Config Name"
                                                    :rules="[rules.requiredString,
                                                        v => uniqueNameRule(v)]"
                                                    @change="handleConfigName()"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="center-x">
                                            <v-col cols="12" sm="6">
                                                <TwoPanelDropDown
                                                    ref="twoPanelDropDown"
                                                    class="px-1 mb-1"
                                                    :allOptions="existingConfigsArray"
                                                    title="Load From Existing (optional)"
                                                    leftPanelLabel="Material Type"
                                                    rightPanelLabel="Material Config"
                                                    @selected-option="selectConfig"
                                                    clearable
                                                    sort
                                                />
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="configType==3" class="center-x">
                                            <v-col cols="12" sm="6">
                                                <v-select
                                                    v-model="basicSettings.material_type_id"
                                                    :items="materialTypes"
                                                    @change="setScaleFactorByMatType"
                                                    item-text="name"
                                                    item-value="id"
                                                    label="Material Type"
                                                    :rules="[rules.requiredSelect]"
                                                >
                                                </v-select>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn color="primary"
                                        :disabled="!configType"
                                        @click="formStepper++"
                                    >Continue
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content class="pa-0" :step="widgetSteps.ckm.step">
                            <v-card class="d-flex flex-column outer-widget-card">
                                <v-card-title class="center-x subtitle-1 font-weight-light">
                                    CKM Settings
                                </v-card-title>
                                <v-card-text>
                                    <v-form v-model="ckmForm" autocomplete="off" ref="ckmForm">
                                        <v-row>
                                            <v-col cols="12" sm="6" >
                                                 <v-col 
                                                    class="center-y center-x mb-6" 
                                                    v-if="configType==3">
                                                    <v-btn-toggle
                                                        v-model="ambientHeatOff" mandatory color="primary" rounded>
                                                        <v-btn :value="false">
                                                            <span style="font-size: 11px">Ambient Heater ON</span>
                                                        </v-btn>
                                                        <v-btn :value="true"> 
                                                            <span style="font-size: 11px">Ambient Heater OFF</span> 
                                                        </v-btn>
                                                    </v-btn-toggle>
                                                </v-col>
                                                <v-col
                                                    v-if="configType == 3"
                                                    class="d-flex align-end pb-3"
                                                >
                                                    <v-text-field
                                                        v-if="!ambientHeatOff"
                                                        :label="materialParams
                                                            .ckm_settings
                                                            .amb_target_temp
                                                            .label"
                                                        v-model="ckmSettings
                                                            .amb_target_temp"
                                                        :suffix="materialParams
                                                            .ckm_settings
                                                            .amb_target_temp
                                                            .units"
                                                        :hint="materialParams
                                                            .ckm_settings
                                                            .amb_target_temp
                                                            .desc"
                                                        :rules="[
                                                            rules.requiredString,
                                                            v => numberRule(v,
                                                            materialParams.ckm_settings.amb_target_temp.min,
                                                            materialParams.ckm_settings.amb_target_temp.max,
                                                            materialParams.ckm_settings.amb_target_temp.units)]"
                                                    ></v-text-field>
                                                </v-col>
                                                 <v-col v-if="configType == 2">
                                                    <v-select
                                                        label="CKM Mode"
                                                        :items="[{text: 'CKM LV: circulation', value: true}, {text: 'CKM OFF: reservoir only, no heat', value: false}]"
                                                        v-model="ckmModeOn"
                                                        item-value="value" item-text="text"
                                                    >
                                                    </v-select>
                                                </v-col>
                                                <v-col v-if="configType == 3 || ckmModeOn" class="d-flex align-end mt-6">
                                                    <v-slider
                                                        label="CKM Speed"
                                                        :min="configType==2 ?
                                                            materialParams.ckm_settings.ckm_mode.min
                                                            : materialParams.ckm_settings.rsvr_temp_pump_lower.min"
                                                        :max="configType==2 ?
                                                            materialParams.ckm_settings.ckm_mode.max
                                                            : materialParams.ckm_settings.rsvr_temp_pump_lower.max"
                                                        step="5"
                                                        thumb-label='always'
                                                        v-model="ckm_speed"
                                                    >
                                                        <template v-slot:append>
                                                            <span class="caption">RPMs</span>
                                                        </template>
                                                    </v-slider>
                                                </v-col>
                                                <v-col
                                                    v-if="configType == 3"
                                                    cols="12"
                                                    sm="12"
                                                    class="center-y center-x"
                                                >
                                                    <v-text-field
                                                        :label="materialParams
                                                            .ckm_settings
                                                            .rsvr_tgt_level
                                                            .label"
                                                        v-model="ckmSettings
                                                            .rsvr_tgt_level"
                                                        :suffix="materialParams
                                                            .ckm_settings
                                                            .rsvr_tgt_level
                                                            .units"
                                                        :hint="materialParams
                                                            .ckm_settings
                                                            .rsvr_tgt_level
                                                            .desc"
                                                        :rules="[
                                                            rules.requiredString,
                                                            v => numberRule(
                                                                v,
                                                                materialParams.ckm_settings.rsvr_tgt_level.min,
                                                                materialParams.ckm_settings.rsvr_tgt_level.max,
                                                                materialParams.ckm_settings.rsvr_tgt_level.units)]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-col>
                                            <v-col v-if="ckmModeOn || configType==3">
                                                <v-col
                                                    class="center-y center-x mb-6"
                                                >
                                                    <v-btn-toggle
                                                        v-model="heatOff" mandatory color="primary" rounded
                                                    >
                                                        <v-btn :value="false">CKM HEAT ON</v-btn>
                                                        <v-btn :value="true" >CKM HEAT OFF</v-btn>
                                                    </v-btn-toggle>
                                                </v-col>
                                                <v-col 
                                                    v-for="(value, setting)
                                                        in filterObject(
                                                            materialParams
                                                            .ckm_settings)"
                                                    :key="setting"
                                                    class="d-flex align-end"
                                                >
                                                    <v-text-field 
                                                        v-if="!heatOff"
                                                        :label="value.label"
                                                        v-model="ckmSettings[setting]"
                                                        :suffix="value.units"
                                                        :hint="value.desc"
                                                        :rules="[
                                                            rules.requiredString,
                                                            v => numberRule(
                                                                v,
                                                                value.min,
                                                                value.max,
                                                                value.units)]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-btn color="primary" outlined
                                        @click="formStepper--"
                                    >
                                        Back
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn color="primary"
                                        @click="formStepper++"
                                    >
                                        Continue
                                    </v-btn>
                                </v-card-actions>
                            </v-card>

                        </v-stepper-content>

                        <v-stepper-content class="pa-0" :step="widgetSteps.peel.step">
                            <v-card class="d-flex flex-column outer-widget-card">
                                <v-card-title class="center-x subtitle-1 font-weight-light">
                                    Peel Settings
                                </v-card-title>
                                <v-card-text>
                                    <v-form v-model="peelForm" autocomplete="off" ref="peelForm">
                                        <v-row>
                                            <v-col 
                                                v-for="(value, setting) in
                                                    filterObject(
                                                        materialParams
                                                        .peel_settings)"
                                                cols="12"
                                                sm="6"
                                                :key="setting"
                                            >
                                                <v-text-field
                                                    :label="value.label"
                                                    v-model="peelSettings[setting]"
                                                    :suffix="value.units"
                                                    :hint="value.desc"
                                                    :rules="[
                                                        rules.requiredString,
                                                        v => numberRule(
                                                            v,
                                                            value.min,
                                                            value.max,
                                                            value.units)]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col 
                                                v-if="configType==3"
                                                cols="12" 
                                                sm="6"
                                                center-x
                                            >
                                                <v-select 
                                                    :label="materialParams
                                                        .peel_settings
                                                        .wiper_print_mode
                                                        .label"
                                                    :items="[{
                                                        text: '0: Layer-Based',
                                                        value: 0}, 
                                                        {text: '1: Time-Based',
                                                        value: 1}]"
                                                    v-model="wiperPrintMode"
                                                    item-value="value" 
                                                    item-text="text"
                                                    @change="handleWiperMode"
                                                    :rules="[rules.requiredSelect]"
                                                >
                                                </v-select>
                                            </v-col>
                                            <v-col
                                                v-if="configType==3
                                                    && wiperPrintMode == 1"
                                                cols="12"
                                                sm="6"
                                            >
                                                <v-text-field
                                                    :label="materialParams
                                                        .peel_settings
                                                        .wiper_print_interval
                                                        .label"
                                                    v-model="peelSettings
                                                        .wiper_print_interval"
                                                    :suffix="materialParams
                                                        .peel_settings
                                                        .wiper_print_interval
                                                        .units"
                                                    :hint="materialParams
                                                        .peel_settings
                                                        .wiper_print_interval
                                                        .desc"
                                                    :rules="[
                                                        rules.requiredString,
                                                        v => numberRule(
                                                            v,
                                                            materialParams
                                                            .peel_settings
                                                            .wiper_print_interval
                                                            .min,
                                                            materialParams
                                                            .peel_settings
                                                            .wiper_print_interval
                                                            .max,
                                                            materialParams
                                                            .peel_settings
                                                            .wiper_print_interval
                                                            .units)]"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                cols="12"
                                                sm="6"
                                                v-if="configType == 3
                                                    && wiperPrintMode == 0"
                                            >
                                                <v-text-field
                                                    :label="materialParams
                                                        .peel_settings
                                                        .layers_per_wipe
                                                        .label"
                                                    v-model="peelSettings
                                                        .layers_per_wipe"
                                                    :suffix="materialParams
                                                        .peel_settings
                                                        .layers_per_wipe
                                                        .units"
                                                    :hint="materialParams
                                                        .peel_settings
                                                        .layers_per_wipe
                                                        .desc"
                                                    :rules="[
                                                        rules.requiredString,
                                                        v => numberRule(
                                                            v,
                                                            materialParams
                                                            .peel_settings
                                                            .layers_per_wipe
                                                            .min,
                                                            materialParams
                                                            .peel_settings
                                                            .layers_per_wipe
                                                            .max,
                                                            materialParams
                                                            .peel_settings
                                                            .layers_per_wipe
                                                            .units)]"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-btn color="primary" outlined
                                        @click="formStepper--"
                                    >
                                        Back
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn
                                        color="primary"
                                        @click="formStepper++"
                                    >
                                        Continue
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content class="pa-0" :step="widgetSteps.plunge.step">
                            <v-card class="d-flex flex-column outer-widget-card">
                                <v-card-title class="center-x subtitle-1 font-weight-light">
                                    Plunge Settings
                                </v-card-title>
                                <v-card-text>
                                    <v-form v-model="plungeForm" autocomplete="off" ref="plungeForm">
                                        <v-row>
                                            <v-col 
                                                v-for="(value, setting)
                                                    in filterObject(
                                                        materialParams
                                                        .plunge_settings)"
                                                :key="setting"
                                                cols="12" 
                                                sm="6" 
                                            >
                                                <v-text-field
                                                    :label="value.label"
                                                    v-model="plungeSettings[setting]"
                                                    :suffix="value.units"
                                                    :hint="value.desc"
                                                    :rules="[
                                                        rules.requiredString,
                                                        v => numberRule(
                                                            v, 
                                                            value.min,
                                                            value.max,
                                                            value.units)]"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-btn color="primary" outlined
                                        @click="formStepper--"
                                    >
                                        Back
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn color="primary"
                                        @click="formStepper++"
                                    >
                                        Continue
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content class="pa-0" :step="widgetSteps.uv.step">
                            <v-card class="d-flex flex-column outer-widget-card">
                                <v-card-title class="center-x subtitle-1 font-weight-light">
                                    UV Settings
                                </v-card-title>
                                <v-card-text>
                                    <v-form v-model="uvForm" autocomplete="off" ref="uvForm">
                                        <v-row>
                                            <v-col v-for="(value, setting) 
                                                in filterObject(
                                                    materialParams.uv_settings)"
                                                :key="setting"
                                                cols="12"
                                                sm="6"
                                            >
                                                <v-text-field
                                                    :label="value.label"
                                                    v-model="uvSettings[setting]"
                                                    :suffix="value.units"
                                                    :hint="value.desc"
                                                    :rules="[
                                                        rules.requiredString,
                                                        v => numberRule(
                                                            v,
                                                            value.min,
                                                            value.max,
                                                            value.units)]"
                                                >
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-btn color="primary" outlined
                                        @click="formStepper--"
                                    >
                                        Back
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn color="primary"
                                        @click="formStepper++"
                                    >
                                        {{internalUse && configType==3 ? "Next" : "Review"}}

                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content v-if="internalUse && configType == 3" 
                            class="pa-0" :step="widgetSteps.default.step">
                            <v-card 
                                class="d-flex flex-column outer-widget-card">
                                <v-card-title 
                                    class="subtitle-1 font-weight-light">
                                    <v-col class="px-0" cols="4" align="left">
                                        <v-btn small color="primary" outlined
                                            @click="resetDefaultNodeStrings()"
                                        >
                                            <v-icon small class="mr-1">
                                                mdi-sync
                                            </v-icon>
                                            Get Defaults
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="4" align="center">
                                        Default Settings
                                    </v-col>
                                    <v-col 
                                        cols="4" 
                                        class="primary--text overline" 
                                        align="right">
                                        Internal Use
                                    </v-col>
                                </v-card-title>
                                <v-card-subtitle>
                                    <v-col class="body-2 font-weight-light" 
                                        cols="12" align="center">
                                        Null values will be automatically 
                                        set to the default values.
                                    </v-col>
                                </v-card-subtitle>
                                <v-card-text>
                                    <v-form v-model="defaultForm" 
                                        autocomplete="off" ref="defaultForm">
                                        <v-row>
                                            <v-col v-for="(value, setting) in numericDefaultValuesMap"
                                                cols="12" sm="4" :key="setting"
                                            >
                                                <v-text-field 
                                                    :class="setting 
                                                        == 'data_dict_version'
                                                        ? 'no-click center-x'
                                                        : 'center-x'"
                                                    :label="setting"
                                                    :value="defaultSettings[setting]"
                                                    v-model="defaultSettings[setting]"
                                                    :hint="'default: ' + defaultValuesMap[setting]"
                                                    :success="defaultSettings[setting] != defaultValuesMap[setting]"
                                                    :rules=[rules.requiredString]
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col v-for="(value, setting) in enumeratedDefaults"
                                                cols="12" sm="4" :key="setting"
                                            >
                                                <v-select v-if="setting != 'donotoverridegcode'"
                                                    :label="setting"
                                                    :items="enumeratedDefaults[setting]"
                                                    v-model="defaultSettings[setting]"
                                                    item-value="value" item-text="text"
                                                    :success="defaultSettings[setting] != defaultValuesMap[setting]"
                                                    :rules=[rules.requiredSelect]
                                                >
                                                </v-select>
                                                <v-checkbox v-if="setting == 'donotoverridegcode'"
                                                    label="doNotOverrideGCode"
                                                    v-model="defaultSettings[setting]"
                                                    :success="defaultSettings[setting] != defaultValuesMap[setting]"
                                                >
                                                </v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-btn color="primary" outlined
                                        @click="formStepper--"
                                    >
                                        Back
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn color="primary"
                                        @click="formStepper++"
                                    >
                                        Next
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content
                            v-if="internalUse && configType == 3"
                            class="pa-0"
                            :step="widgetSteps.override.step">
                            <v-card 
                                class="d-flex flex-column outer-widget-card">
                                <v-card-title 
                                    class="subtitle-1 font-weight-light">
                                    <v-spacer></v-spacer>
                                    <v-col cols="4" align="center">
                                        Configs Override
                                    </v-col>
                                    <v-col
                                        cols="4"
                                        class="primary--text overline"
                                        align="right">
                                        Internal Use
                                    </v-col>
                                </v-card-title>
                                <v-card-subtitle>
                                    <v-col
                                        class="body-2 font-weight-light"
                                        cols="12"
                                        align="center"
                                    > Warning: Only override system configs, not factory/calibration/material.<br>
                                    Some configs may not be overridden in FW. Use with discretion.
                                    </v-col>
                                </v-card-subtitle>
                                <v-card-text>
                                    <v-form v-model="overrideForm"
                                        autocomplete="off" ref="overrideForm">
                                        <v-row v-for="(overrideConfigRow, i) of overrideConfigs"
                                                cols="12" sm="4" :key="`overrideConfigRow${ i }`">
                                            <v-col>
                                                <v-text-field
                                                    :class="'center-x'"
                                                    :label="`Config ${i+1}`"
                                                    v-model="overrideConfigs[i].nodestring"
                                                    :rules=[rules.requiredString]
                                                ></v-text-field>
                                            </v-col>
                                            <v-col>
                                                <v-text-field
                                                    :class="'center-x'"
                                                    :label="`Config ${i+1} Value`"
                                                    v-model="overrideConfigs[i].value"
                                                    :rules=[rules.isNumber]
                                                ></v-text-field>
                                            </v-col>
                                            <v-col cols="1" class="d-flex align-center pl-3">
                                                <v-btn
                                                    @click="removeStream(i)"
                                                    icon
                                                >
                                                    <v-icon>mdi-close</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col cols="12" class="center-x pt-6">
                                                <v-btn
                                                    @click="addStreams()"
                                                    color="primary"
                                                    icon outlined
                                                >
                                                    <v-icon>mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-btn color="primary" outlined
                                        @click="formStepper--"
                                    >
                                        Back
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn color="primary"
                                        @click="formStepper++"
                                    >
                                        Next
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content v-if="internalUse && configType == 3" 
                            class="pa-0" :step="widgetSteps.printQuality.step">
                            <v-card 
                                class="d-flex flex-column outer-widget-card">
                                <v-card-title 
                                    class="subtitle-1 font-weight-light">
                                    <v-spacer></v-spacer>
                                    <v-col align="center">
                                        Print Quality Settings
                                    </v-col>
                                    <v-col
                                        cols="4"
                                        class="primary--text overline" 
                                        align="right">
                                        Internal Use
                                    </v-col>
                                </v-card-title>
                                <v-card-text>
                                    <v-form
                                        v-model="printQualityForm"
                                        autocomplete="off"
                                        ref="printQualityForm"
                                    >
                                        <v-row class="mx-2">
                                            <v-textarea
                                                outlined
                                                class="center-x"
                                                label="Print Quality Configs"
                                                :placeholder="printQualityPlaceholder"
                                                v-model="printQualitySettings"
                                                hint="Paste in print quality configs."
                                                :rules=[validPrintQualityRule]
                                                :success="validPrintQuality(printQualitySettings)"
                                                rows="6"
                                            >
                                            </v-textarea>
                                        </v-row>
                                        <v-row class="mx-0">
                                            <v-col cols="6">
                                                <v-text-field
                                                    v-model="defaultSettings.material_scale_factor"
                                                    label="Material Scale Factor"
                                                    :rules=[rules.isNumber]
                                                    class="center-x"
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-btn color="primary" outlined
                                        @click="formStepper--"
                                    >
                                        Back
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn color="primary"
                                        @click="formStepper++"
                                    >
                                        Review
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content readonly class="pa-0" 
                            :step="widgetSteps.review.step">
                            <v-card class="outer-widget-card">
                                <v-card-title class="center-x py-2 subtitle-1 font-weight-light">
                                    <span>Please review
                                    <span class="font-weight-bold"> 
                                        {{ configName }} </span>
                                    before saving</span>
                                </v-card-title>
                                <v-card-text>
                                    <MaterialConfigDisplay
                                    :configCreationDisplay="true"
                                    :matTypes="materialTypes"
                                    :configData="{...basicSettings,
                                        ...ckmSettings,
                                        ...peelSettings,
                                        ...plungeSettings,
                                        ...uvSettings,
                                        ...defaultSettings}"
                                />
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-btn color="primary" outlined
                                        @click="formStepper--"
                                    >
                                        Back
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn color="primary"
                                        @click="saveConfig()"
                                        :disabled="disableSave()"
                                    >
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {DEFAULT_SCALE_FACTORS} from '../assets/js/materialConstants.js';
import MaterialConfigDisplay from "./MaterialConfigDisplay";
import TwoPanelDropDown from './TwoPanelDropDown.vue';

export default {
    props: ['existingConfigs2x', 'existingConfigs3x'],
    components: {
        MaterialConfigDisplay,
        TwoPanelDropDown,
    },
    data() {
        return {
            basicForm: undefined,
            ckmForm: undefined,
            peelForm: undefined,
            plungeForm: undefined,
            uvForm: undefined,
            defaultForm: undefined,
            overrideForm: undefined,
            printQualityForm: undefined,
            formParent: undefined,
            formStepper: 1,
            widgetSteps: {
                basic: {
                    step: 1,
                    name: 'Basics',
                },
                ckm: {
                    step: 2,
                    name: 'CKM',
                },
                peel: {
                    step: 3,
                    name: 'Peel',
                },
                plunge: {
                    step: 4,
                    name: 'Plunge',
                },
                uv: {
                    step: 5,
                    name: 'UV',
                },
                default: {
                    step: 6,
                    name: 'Default'
                },
                override: {
                    step: 7,
                    name: 'Override'
                },
                printQuality: {
                    step: 8,
                    name: 'Print Quality'
                },
                review: {
                    step: 6,
                    name: 'Review',
                },
            },
            configName: undefined,
            baseConfig: undefined,
            ckmModeOn: true,
            heatOff: false,
            ambientHeatOff: false,
            tempRange: [],
            basicSettings: {},
            ckmSettings: {},
            peelSettings: {layer_height: 50},
            plungeSettings: {},
            uvSettings: {},
            defaultSettings: {},
            enumeratedDefaults: {
                'wiper_air_mode': [{text: '0: WIPER_AIR_NONE', value: 0},
                    {text: '1: WIPER_AIR_FIRST', value: 1}, 
                    {text: '2: WIPER_AIR_RETURN', value: 2}, 
                    {text: '3: WIPER_AIR_BOTH', value: 3}, 
                    {text: '4: WIPER_AIR_BOTH_FIRST_EXTENDED', value: 4}],
                'donotoverridegcode': [{text: 'True', value: true},
                    {text: 'False', value: false}]
            },
            configType: undefined,
            materialTypes: [],
            materialType: undefined,
            ckm_speed: 5,
            rerender: 0,
            overrideConfigs: [],
            printQualitySettings: {},
            validPrintQualityRule: value => this.validPrintQuality(value) || 'Invalid Json.',
            printQualityPlaceholder: '{"bufferLayersAtStart": 20, \
                \n . . . ,  \
                \n "moduleList": [ \
                \n\t{ . . . } \
                \n]}',
            wiperPrintMode: undefined,
            versionedExistingConfigs: [],
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'dialogs',
            'rules',
            'matParams', 
            'matParams3x', 
            'internalUse',
            'matConfigComms',
            'iam',
            'matConfigDefaultValueExceptions',
        ]),
        ...mapGetters([
            'show2x',
            'show3x',
            'onlyOneSeriesAvail'
        ]),
        existingConfigNames() {
            let names = this.versionedExistingConfigs
            .map(i => i.name.toLowerCase());
            return names;
        },
        materialParams(){
            if (this.configType == 2) {
                return this.matParams;
            }
            else {
                return this.matParams3x
            }
        },
        existingConfigsArray() {
            let versionedConfigs = [];
            let versionedConfigsArray = [];
            if (this.configType == 2) {
                versionedConfigs = this.existingConfigs2x;
            }
            else if (this.configType == 3) {
                versionedConfigs = this.existingConfigs3x;
            }

            versionedConfigs.forEach((option) => {
                if (option.material_type == undefined
                    || option.material_type == null) {
                    option.material_type = "N/A";
                }
                if (!versionedConfigsArray.hasOwnProperty(option.material_type)) {
                    versionedConfigsArray[option.material_type] = {
                        disabled: false,
                        list: []
                    };
                }
                let icon = 'mdi-check';
                if (option.retired) {
                    icon = 'mdi-delete-variant'
                }

            versionedConfigsArray[option.material_type]
                .list.push({
                    desc: option.name,
                    id: option.id,
                    icon: icon,
                    retired: option.retired
                })
            });
            this.versionedExistingConfigs = versionedConfigs;
            return versionedConfigsArray;
        },
        defaultValuesMap() {
            return this.matConfigComms.defaultSettingsMap;
        },
        // generate obj of node strings to display on step 6
        numericDefaultValuesMap() {
            let numDefaultValuesMap = {};

            Object.keys(this.matConfigComms.defaultSettingsMap).forEach(key => {
                if (!this.enumeratedDefaults.hasOwnProperty(key)
                    && !this.matConfigDefaultValueExceptions.includes(key)) {
                    numDefaultValuesMap[key]
                        = this.matConfigComms.defaultSettingsMap[key];
                }
            });
            return numDefaultValuesMap;
        },
        overrideConfigsList() {
            let list = {};
            this.overrideConfigs.forEach(config => {
                list[config.nodestring]= parseFloat(config.value);
            })
            return list;
        }
    },
    watch: {
        formStepper(val) {
            if (val > this.widgetSteps.basic.step) {
                this.$refs.basicForm.validate();
            }
            if (val > this.widgetSteps.ckm.step) {
                this.$refs.ckmForm.validate();
            }
            if (val > this.widgetSteps.peel.step) {
                this.$refs.peelForm.validate();
            }
            if (val > this.widgetSteps.plunge.step) {
                this.$refs.plungeForm.validate();
            }
            if (val > this.widgetSteps.uv.step) {
                this.$refs.uvForm.validate();
            }
            if (val > this.widgetSteps.default.step) {
                this.$refs.defaultForm.validate();
            }
            if (val > this.widgetSteps.override.step) {
                this.$refs.overrideForm.validate();
            }
            if (val > this.widgetSteps.printQuality.step) {
                this.$refs.printQualityForm.validate();
            }
            if (val == this.widgetSteps.review.step) {
                this.$refs.uvForm.validate();
                this.adjustInputValues();
            }
        },
        'matConfigComms.ready': function(val) {
            if (val == true) {
                this.resetDefaultSettings();
            }
        },
        printQualitySettings: function(val) {
            this.defaultSettings.print_quality = val;
        },
        overrideConfigsList: function(val) {
            this.defaultSettings.config_override = val;
        }
    },
    mounted() {
        // set the config type to the first one in the available series array
        this.configType = Number(this.iam.availableSeries[0]);
        this.handleConfigType();

        this.getMaterialTypes();
        this.resetDefaultSettings();
    },
    methods: {
        // checks if input is null, empty string, or valid json
        validPrintQuality(val) {
            if (val == null || val == "") {
                return true;
            }
            try {
                let parsed = JSON.parse(val);
                if (typeof parsed == 'string') {
                    return false;
                }
                return true;
            }
            catch(e) {
                return false;
            }
        },
        addStreams(count=1) {
            for(let c = 0; c < count; c++) {
                this.overrideConfigs.push({});
            }
        },
        removeStream(index) {
            if (this.overrideConfigs.length == 0) { return }
            this.overrideConfigs.splice(index, 1);
        },
        handleConfigName() {
         this.basicSettings.name = this.basicSettings.name.trim();
        },
        closeModal() {
            this.dialogs.materialConfigForm.open = false;
        },
        // performs config series specific actions 
        handleConfigType() {
            this.resetConfigForm(); 
            this.cleanUpVersionedSettings();
            this.setReviewFormStep();
        },
        handleWiperMode() {
        // 0: layer based, wiper_print_interval is null
        // 1: time-based, layers_per_wipe is null
            if (this.wiperPrintMode == 0) {
                this.peelSettings.wiper_print_interval = null;
            }
            else {
                this.peelSettings.layers_per_wipe = null;
            }
        },
        setReviewFormStep() {
            if(this.internalUse == true && this.configType == 3) {
                this.widgetSteps.review.step = 9;
            }
            else {
                this.widgetSteps.review.step = 6;
            }
        }, 
        // gets material parameters based on configuration type: 2 or 3 series
        async getMaterialTypes() {
            if (!this.show3x) { return; }

            let vm = this;
            try {
                let t = await vm.$auth.getTokenSilently();
                let matTypes = await vm.$http
                .get(vm.baseURL+'/database/matTypes', {
                    headers: { Authorization: `Bearer ${t}` }
                })
                vm.materialTypes = matTypes.data.body;
            }
            catch(e) {
                console.log("Error getting material types. ", e)
            }
        },
        adjustInputValues() {
            this.basicSettings.config_series = this.configType;

            if (this.configType == 2) {
                this.adjustInputValues2x();
            }
            else {
                this.adjustInputValues3x();
            }
        },
        adjustInputValues2x() {
            this.ckmSettings.ckm_mode = this.ckm_speed;
            
            if (!this.ckmModeOn) { // CKM Resonly
                this.ckmSettings.ckm_mode = 0;
                this.heatOff = true;
            }

            // if no heat, set the default values
            if (this.heatOff) {
                this.ckmSettings.resin_temp = 23;
                this.ckmSettings.max_wall_temp = 23;
                this.ckmSettings.aux_htr_max_temp = 25;
            }

            // CKM LV with heat, set aux_htr_max to +10
            if (this.ckmModeOn && !this.heatOff) {
                this.ckmSettings.aux_htr_max_temp 
                = parseInt(this.ckmSettings.max_wall_temp) + 10;
            }
        },
        // for 3x input values that aren't bound to
        // ckmSettings/peelSettings fields
        adjustInputValues3x() {
            this.ckmSettings.rsvr_temp_pump_lower = this.ckm_speed;
            this.peelSettings.wiper_print_mode = this.wiperPrintMode;
            this.setMaterialTypeName();

            // if no ckm heat, set ckm heat disabled to true 
            // and remove inlet temp
            this.ckmSettings.ckm_heat_disable = false;
            if (this.heatOff) {
                this.ckmSettings.ckm_heat_disable = true;
                this.ckmSettings.circag_tgt_inlet_temp = null;
            }
            // if no ambient heat, ambient heat enabled is false 
            // and ambient target temp is null
            this.ckmSettings.amb_heat_enable = true;
            if (this.ambientHeatOff) {
                this.ckmSettings.amb_heat_enable = false;
                this.ckmSettings.amb_target_temp= null;
            }
        },
        setMaterialTypeName() {
            for (var indx in this.materialTypes) {
                if (this.materialTypes[indx].id 
                    == this.basicSettings.material_type_id) {
                    this.basicSettings.material_type 
                        = this.materialTypes[indx].name;
                }
            }
        }, 
        // reset settings to be empty
        cleanUpVersionedSettings(){
            this.basicSettings = {};
            this.ckmSettings = {};
            this.peelSettings = {};
            this.plungeSettings = {};
            this.uvSettings = {};
            this.resetDefaultSettings();

            if (this.configType == 2) {
                this.peelSettings.layer_height = 50;
            }
        },
        resetConfigForm(restoreConfigName=false){
            let configName;
            if (restoreConfigName) {
                configName = this.basicSettings.name;
            }
            this.formStepper = 1;

            if (this.$refs.formParent != undefined) {
                this.$refs.basicForm.reset();
                this.$refs.ckmForm.reset();
                this.$refs.peelForm.reset();
                this.$refs.plungeForm.reset();
                this.$refs.uvForm.reset();
            }

            if (this.configType == 2) {
                this.peelSettings.layer_height = 50;
                this.ckmModeOn = true;
            }
            else {
                this.resetDefaultSettings();
            }
            this.basicSettings.name = configName;
            this.rerender++;
        },
        selectConfig(selected) {
            this.baseConfig = undefined;
            if (selected) {
                [this.baseConfig]
                    = this.versionedExistingConfigs
                    .filter(config => config.name == selected.desc);
            }
            this.setExistingConfigValues(this.baseConfig);
        },
        setExistingConfigValues(selected) {
            // if non selected, reset all subsequent forms
            if (!selected) {
                this.resetConfigForm(true);
            }
            else {
                // load values from selected config to params without a local
                // variable equivalent
                let materialParamObject = {
                    'ckm_settings': this.ckmSettings,
                    'peel_settings': this.peelSettings,
                    'plunge_settings': this.plungeSettings,
                    'uv_settings': this.uvSettings
                }

                Object.entries(materialParamObject).map(([key, value]) => {
                    Object.keys(
                        this.filterObjectsWithLocalVars(this.materialParams[key]))
                    .forEach(setting => {
                        value[setting] = selected[setting];
                    });
                })

                if (this.configType == 2) {
                    this.setExistingConfigExceptions2x(selected);
                }
                else if (this.configType == 3) {
                    this.setExistingConfigExceptions3x(selected);
                }
            }
        },
        setExistingConfigExceptions2x(selected) {
            this.ckmModeOn = (selected.ckm_mode != 0)
            this.ckm_speed = selected["ckm_mode"];
            this.heatOff = (selected.ckm_mode == 0 ||
                (selected['resin_temp'] == selected['max_wall_temp']));
        },
        setExistingConfigExceptions3x(selected) {
            this.ambientHeatOff = !selected["amb_heat_enable"];
            this.ckm_speed = selected["rsvr_temp_pump_lower"];
            this.heatOff = selected["ckm_heat_disable"];
            this.basicSettings["material_type_id"]
                = selected["material_type_id"];
            this.defaultSettings.material_scale_factor = selected["material_scale_factor"];
            this.wiperPrintMode = selected["wiper_print_mode"];

            // If internalUse, set the defaultSettings to the
            // values from the selected existing material config.
            // Customers will always get the latest default values from
            // the DB regardless of the selected existing config.
            if (this.internalUse) {
                Object.keys(this.defaultValuesMap)
                .forEach(key =>
                    this.defaultSettings[key] = selected[key]);

                this.defaultSettings.data_dict_version
                    = this.defaultValuesMap.data_dict_version;
                this.defaultSettings.config_override
                    = JSON.parse(selected.config_override);
                this.mapOverrideConfigs();

                if (this.defaultSettings.print_quality
                    && this.validPrintQuality(
                        this.defaultSettings.print_quality)) {
                    this.printQualitySettings
                        = JSON.stringify(
                            JSON.parse(this.defaultSettings.print_quality),
                            null,
                            "\t");
                }
                else {
                    this.printQualitySettings = undefined;
                }
            }
        },
        // map json object for config_override to overrideConfigs list with
        // [{nodestring: x, value: y}] format
        mapOverrideConfigs() {
            this.overrideConfigs = [];
            if (this.defaultSettings.config_override) {
                Object.keys(this.defaultSettings.config_override).forEach(key => {
                    let obj = {
                        nodestring: key,
                        value: this.defaultSettings.config_override[key]
                    }
                    this.overrideConfigs.push(obj);
                })
            }
        },
        // minValueOrVar can be a number or a variable that represents
        // a user-inputted config value
        // If minValueOrVar isNan, we set the minimum to the variable + 15.
        numberRule(v, minValueOrVar, max, units) {
            if (!v) return true;
            if (!isNaN(parseFloat(v))) {
                if (isNaN(minValueOrVar)) {
                    minValueOrVar = this.findDependentMinByKey(minValueOrVar);
                }
                if (v >= minValueOrVar && v <= max) return true;
            }
            return `Must be between ${minValueOrVar} and ${max} ${units || ""}`;
        },
        // Find which material settings object the given key belongs to.
        // Return the value + 15 since it is currently only used for resin_temp.
        findDependentMinByKey(targetKey) {
            let objectsArray = [
                this.ckmSettings,
                this.basicSettings,
                this.peelSettings,
                this.plungeSettings,
                this.uvSettings
            ]
            let settings = objectsArray.find(obj => targetKey in obj);
            let min = parseInt(settings[targetKey]) + 15;
            return min;
        },
        uniqueNameRule(v) {
            if(v && this.existingConfigNames.includes(v.toLowerCase())) {
                return 'Name already used';
            }
            return true;
        },
        setScaleFactorByMatType(selectedId) {
            this.defaultSettings.material_scale_factor = 1;

            let matchedObjs = this.materialTypes.filter(typeObj => typeObj.id == selectedId);
            if (matchedObjs.length > 0) {
                this.defaultSettings.material_scale_factor = DEFAULT_SCALE_FACTORS[matchedObjs[0].name] || 1;
            }
            console.log("scale factor defaulted to ", this.defaultSettings.material_scale_factor)
        },
        resetDefaultSettings() {
            if (this.configType == 3) {
                this.resetDefaultNodeStrings();
                this.printQualitySettings = undefined;
                this.defaultSettings.material_scale_factor = undefined;
                this.overrideConfigs = [];
            }
            else {
                this.defaultSettings = {};
                this.printQualitySettings = undefined;
                this.overrideConfigs = [];
            }
        },
        resetDefaultNodeStrings() {
            if (this.configType == 3) {
                this.defaultSettings
                    = JSON.parse(JSON.stringify(this.defaultValuesMap));
            }
        },
        disableSave() {
            if (this.configType == 2 || !this.internalUse) {
                return !(this.basicForm
                        && this.ckmForm
                        && this.peelForm
                        && this.plungeForm
                        && this.uvForm)
            }
            else {
                return !(this.basicForm
                        && this.ckmForm
                        && this.peelForm
                        && this.plungeForm
                        && this.uvForm
                        && this.defaultForm
                        && this.overrideForm
                        && this.printQualityForm)
            }
        },
        // For 3 series configs, default settings that are left null in the form
        // will be sent to the to the microserver as null. The DB microserver 
        // will then populate the null settings with values from the 
        // material_config_3x_default table.
        async saveConfig() {
            let payload = {
                basicSettings: {name: this.basicSettings.name},
                ckmSettings: this.ckmSettings,
                peelSettings: this.peelSettings,
                plungeSettings: this.plungeSettings,
                uvSettings: this.uvSettings,
            }

            if (this.configType == 3) {
                payload.basicSettings.material_type_id = this.basicSettings.material_type_id;
                payload.defaultSettings = this.defaultSettings;
                delete payload.defaultSettings.config_override;
                delete payload.defaultSettings.print_quality;

                if (this.internalUse) {
                    if (Object.keys(this.overrideConfigsList).length > 0) {
                        payload.defaultSettings.config_override
                            = JSON.stringify(this.overrideConfigsList);
                    }
                    // if not null and not empty string
                    if (this.printQualitySettings != null 
                        && this.printQualitySettings != "") {
                        payload.defaultSettings.print_quality
                            = this.printQualitySettings.replace(/[\n\r\t\s]/g,"");
                    }
                }
                try {
                    await this.matConfigComms.writeMatConfig3x(payload);
                    console.log("save 3x config: ", JSON.stringify(payload));
                }
                catch(err) {
                    console.error("Failed to write mat config: ", 
                        JSON.stringify(err));
                    return;
                }
            }

            else {
                try {
                    await this.matConfigComms.writeMatConfig2x(payload);
                    console.log("save 2x config ", JSON.stringify(payload));
                }
                catch(err) {
                    console.error("Failed to write mat config: ", 
                        JSON.stringify(err));
                    return;
                }
            }

            this.$emit('update-material-configs');
            //reset materialconfig form
            this.closeModal();
            this.resetConfigForm();
        },
        // filter out objects with a hidden parameter
        // hidden means it has a local variable 
        filterObjectsWithLocalVars(objectList) {
            let filtered = Object.entries(objectList)
                .filter(([key, value]) => !value.hasLocalVar);
            return Object.fromEntries(filtered);
        },
        // filter out object with local variable or specialDisplay parameter
        // special display means it's not a text field or the UI placement is
        // controlled
        filterObject(objectList) {
            let filtered = Object.entries(objectList)
                .filter(([key, value]) => !value.hasLocalVar && !value.specialDisplay);
            return Object.fromEntries(filtered);
        }
    }
}
</script>

<style scoped>
[class*="col"] {
    padding: 0 10px
}

.outer-widget-card {
    background-color: #272727;
    min-height: 350px;
}
</style>