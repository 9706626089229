<template>
    <v-row>
        <v-menu offset-y :close-on-content-click="false" :disabled="disabled" class="ma-2"
            v-model="showMenuCard" transition="scale-transition"
        >
            <template v-slot:activator="{ on }">
                <v-row
                    v-on="on"
                    :class="['mx-2',
                        disabled ? 'drop-down-line-inactive': 'drop-down-line-active']"
                >
                    <v-col cols="11" class="mx-0 px-0 py-1">
                        <span :class="[!selectedOne? 'pb-1 body-1' : 'pb-0 caption']">
                            {{ title }}
                        </span>
                    </v-col>
                    <span
                        v-if="selectedOne"
                        :class="['body-1 pb-1', disabled ? 'gray--text' : 'white--text',
                            checkSmallFontNeeded(selectedOne), 'material-name-truncate']"
                    >{{selectedOne}}
                    </span>
                    <v-spacer/>
                    <v-icon
                        v-if="clearable && selectedOne"
                        @click.stop="clearSelection"
                        :color="disabled ? 'grey' : 'gray'">mdi-close
                    </v-icon>
                    <v-icon :color="disabled ? 'grey' : 'gray'">mdi-menu-down</v-icon>
                </v-row>
            </template>
            <v-card outlined class="two-panel-card d-flex flex-column">
                <v-card-text>
                    <v-row>
                        <v-col cols="4" class="card-col">
                            <span
                                color="grey"
                                class="list-item-height pb-2 overline"
                            >{{leftPanelLabel}}
                            </span>
                            <v-list flat>
                                <v-list-item-group
                                    v-model="selectedTypeIndex"
                                    color="primary"
                                    mandatory
                                >
                                    <v-list-item
                                        v-for="(item, index) in Object.keys(displayedOptions)"
                                        :key="index"
                                        @click="selectedType=item"
                                        class="list-item-height"
                                        :color="allOptions[item].disabled ? 'gray' : 'primary'"
                                        :disabled="allOptions[item].disabled"
                                    >
                                        <v-list-item-title>{{ item }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                        <v-col cols="1" align="right">
                            <v-divider vertical></v-divider>
                        </v-col>
                        <v-col cols="7" class="card-col">
                            <span color="grey" class="list-item-height mt-n4 pb-2 overline">
                                {{rightPanelLabel}}
                            </span>
                            <v-list
                                v-if="selectedType"
                                :key="selectedType"
                                class="pr-2 scroll-panel">
                                <v-list-item-group
                                    :value="selectedOneIndex"
                                    color="primary">
                                    <v-list-item
                                        v-for="(item, index) in allOptions[selectedType].list"
                                        :key="selectedType+index"
                                        @click="selectOne(item, index)" color="primary"
                                        class="list-item-height"
                                    >
                                    <v-list-item-icon
                                        v-if="item.icon"
                                        class="mr-2 my-auto"
                                    >
                                        <v-icon color="grey">{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                        <v-list-item-title
                                            style="white-space: normal"
                                        >{{ item.desc }}
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-spacer/>
                <v-card-actions v-if="bottomNote" class="pa-3 justify-end">
                    <span class="body-2"> * {{ bottomNote }}</span>
                </v-card-actions>
            </v-card>
        </v-menu>
    </v-row>
</template>

<script>
export default {
    name: 'TwoPanelDropDown',
    props: {
        allOptions: {
            type: Array,
            required: true
        },
        bottomNote: {
            type: String,
            required: false
        },
        clearable: {
            type: Boolean,
            required: false
        },
        disabled: {
            type: Boolean,
            required: false
        },
        leftPanelLabel: {
            type: String,
            required: false
        },
        preSelected: {
            type: String,
            required: false
        },
        rightPanelLabel: {
            type: String,
            required: false
        },
        sort: {
            type: Boolean,
            required: false
        },
        title: {
            type: String,
            required: true
        }
    },
    data: () => ({
        showMenuCard: false,
        selectedType: undefined,
        selectedTypeIndex: 0,
        selectedOne: undefined,
    }),
    computed: {
        selectedOneIndex() {
            return this.allOptions[this.selectedType].list
                .findIndex( x => x.desc == this.selectedOne);
        },
        displayedOptions() {
            if (this.sort) {
                Object.keys(this.allOptions).forEach(type => {
                    this.allOptions[type].list.sort((a,b)=> {
                        return (+a.retired) - (+b.retired) || a.desc.localeCompare(b.desc);
                    })
                })
            }
            return this.allOptions;
        }
    },
    watch: {
        preSelected: {
            handler() {
                if (!this.preSelected) { return }

                this.selectedOne = this.preSelected;
                this.setTypeBasedOnPreselected();
            },
            immediate: true
        },
        allOptions: {
            handler() {
                if (!this.selectedType) {
                    if (this.preSelected) {
                        this.setTypeBasedOnPreselected();
                    }
                    else {
                        this.setDefaultType();
                    }
                }
            }
        }
    },
    methods: {
        selectOne(selected) {
            this.showMenuCard = false;
            this.selectedOne = selected.desc;
            this.$emit('selected-option', selected);
        },
        setTypeBasedOnPreselected() {
            Object.keys(this.allOptions).forEach((key, i) => {
                if (this.allOptions[key].list.find(x => x.desc == this.selectedOne)) {
                    this.selectedTypeIndex = i;
                    this.selectedType = key;
                }
            })
        },
        setDefaultType() {
            this.selectedTypeIndex = 0;
            this.selectedType = Object.keys(this.allOptions)[this.selectedTypeIndex];
        },
        checkSmallFontNeeded(name) {
            if (name && name.length > 30) // greater than 30 characters
                return 'small-text';
        },
        clearSelection() {
            this.selectedOne = undefined;
            this.$emit('selected-option', undefined);
        }
    },
    created() {
        if (!this.preSelected) {
            this.setDefaultType();
        }
    }
  }
</script>

<style scoped>
.list-item-height {
    min-height: 32px;
    padding: 0px 6px;
    margin: 0px 0;
}
.card-col {
    padding: 0 8px;
}
.two-panel-card {
    width: 550px;
}
.scroll-panel {
    max-height: 350px;
    overflow-y: auto;
}
.drop-down-line-active {
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
}
.drop-down-line-inactive {
    border-bottom: 1px dashed gray;
}
.small-text {
    font-size: 25px;
}

.material-name-truncate {
    text-overflow: ellipsis;
    width: 265px;
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
    text-align: left
}

</style>