<template>
    <v-navigation-drawer app expand-on-hover permanent>
        <v-list dense>
            <UserProfile/>
            <v-divider></v-divider>

            <router-link 
                v-for="(tab, index) in tabs"
                v-bind:key="index"
                :to="tab.routeLink"
            >
                <v-list-item link>
                    <v-list-item-icon>
                        <v-icon color="grey lighten-3">{{tab.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{tab.title}}</v-list-item-title>
                </v-list-item>
            </router-link>

        </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapMutations, mapGetters, mapState} from 'vuex';
import UserProfile from './UserProfile.vue';

export default {
    components: {
        UserProfile,
    },
    data: function(){
        return {
            //
        }
    },
    computed: {
        ...mapState([
            'iam',
            'internalUse'
        ]),
        ...mapGetters([
            'fluxdevUser',
        ]),
        tabs() {
            let allTabs = [
                // {
                //     title: 'Dashboard',
                //     icon: 'mdi-view-dashboard',
                //     routeLink: '/about',
                // },
                {
                    title: 'Printers',
                    icon: 'mdi-printer-3d',
                    routeLink: '/printers/home',
                    show: true,
                },
                {
                    title: 'Prints',
                    icon: 'mdi-cube-outline',
                    routeLink: '/prints/home',
                    show: true,
                },
                {
                    title: 'Materials',
                    icon: 'mdi-beaker',
                    routeLink: '/materialConfigList',
                    show: this.iam.fluxdev,
                },
                {
                    title: 'Jobs',
                    icon: 'mdi-briefcase-variant',
                    routeLink: '/jobs/home',
                    show: this.internalUse,
                },
                {
                    title: 'Fortify Releases',
                    icon: 'mdi-file-download',
                    routeLink: '/releases/home',
                    show: true,
                },
            ]

            return allTabs.filter(tab => tab.show);
        }
    },
    watch: {
        //
    },
    methods: {
        //
    }
}
</script>


<style>

</style>