<template>
    <v-dialog v-model="dialogs.createJobForm.open" persistent
        max-width="1000px" max-height="900px" overlay-opacity="0.85"
    >
        <v-card min-height="500" style="overflow-y: hidden">
            <v-card-title>
                <span>Create New Job</span>
                <v-spacer />
                <v-btn 
                    @click="dialogs.createJobForm.open=false"
                    icon color="primary"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-stepper non-linear v-model="formStepper">
                    <v-stepper-header>
                        <v-stepper-step
                            editable color="green"
                            :rules="[() => basicForm || formStepper < widgetSteps.basic.step]"
                            :complete="formStepper > widgetSteps.basic.step"
                            :step="widgetSteps.basic.step"
                        >
                            {{ widgetSteps.basic.name }}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            editable color="green"
                            :rules="[() => inputs.printFileId!=undefined || formStepper < widgetSteps.printFile.step]"
                            :complete="formStepper > widgetSteps.printFile.step"
                            :step="widgetSteps.printFile.step"
                        >
                            {{ widgetSteps.printFile.name }}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                            editable color="green"
                            :rules="[() => matConfigForm ||formStepper < widgetSteps.matConfig.step]"
                            :complete="formStepper > widgetSteps.matConfig.step"
                            :step="widgetSteps.matConfig.step"
                        >
                            {{ widgetSteps.matConfig.name }}
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step 
                            editable color="green"
                            :step="widgetSteps.review.step"
                        >
                            {{ widgetSteps.review.name }}
                        </v-stepper-step>
                    </v-stepper-header>


                    <v-stepper-items>
                        <v-stepper-content class="pa-0" :step="widgetSteps.basic.step">
                            <v-card class="d-flex flex-column outer-widget-card">
                                <v-card-title class="center-x subtitle-1 font-weight-light">
                                    Create New Job
                                </v-card-title>
                                <v-card-text class="scroll-text">
                                    <v-form v-model="basicForm" autocomplete="off" ref="basicForm">
                                         <v-row class="center-x center-y">
                                             <v-col cols="6">
                                                <v-text-field v-model="inputs.name"
                                                    label="Job Name"
                                                    :rules="[rules.requiredString,
                                                        v => uniqueNameRule(v)]"
                                                    @change="handleJobName"
                                                ></v-text-field>
                                             </v-col>
                                        </v-row>
                                    </v-form>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn color="primary" :disabled="!basicForm" @click="formStepper++">
                                        Continue
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content class="pa-0" :step="widgetSteps.printFile.step">
                            <v-card class="d-flex flex-column outer-widget-card">
                                <v-card-title class="center-x subtitle-1 font-weight-light pb-2">
                                    <v-btn-toggle v-model="printFileSortBy" @change="sortPrintFile"
                                        mandatory dense color="primary" rounded
                                    >
                                        <v-btn :value="1" class="px-2">
                                            <v-icon small>mdi-calendar</v-icon> NEW
                                        </v-btn>
                                        <v-btn :value="2" class="px-2">
                                            <v-icon small>mdi-calendar</v-icon> OLD
                                        </v-btn>
                                        <v-btn :value="3" class="px-2">
                                            <v-icon>mdi-order-alphabetical-ascending</v-icon>
                                        </v-btn>
                                        <v-btn :value="4" class="px-2">
                                            <v-icon>mdi-order-alphabetical-descending</v-icon>
                                        </v-btn>
                                    </v-btn-toggle>
                                    <v-spacer />
                                    <span class="absolute-center">Select the Print File</span>
                                    <v-spacer />
                                    <v-btn icon outlined @click="updatePrintFiles()" color="primary">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                </v-card-title>
                                <v-card-text class="scroll-text">
                                    <v-row>
                                    <v-layout wrap>
                                        <v-flex xs12 v-for="(i, j) in cardsToRender" :key="i"
                                            class="px-2 py-1"
                                        >
                                            <v-skeleton-loader
                                                type="list-item-avatar-three-line, actions" v-if="loading"
                                                class="ma-2"
                                            ></v-skeleton-loader>
                                            <PrintFileCard v-else
                                                :printFile="cloud3dfArr[j]"
                                                :selected="cloud3dfArr[j].id == inputs.printFileId"
                                                :clickable="true"
                                                @select-print-file="setSelectedPrintFile(cloud3dfArr[j].id)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    </v-row>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-btn color="primary" outlined @click="formStepper--">
                                        Back
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn color="primary" :disabled="!inputs.printFileId" @click="formStepper++">
                                        Continue
                                    </v-btn>
                                </v-card-actions>
                            </v-card>

                        </v-stepper-content>

                        <v-stepper-content class="pa-0" :step="widgetSteps.matConfig.step">
                            <v-card class="d-flex flex-column outer-widget-card">
                                <v-card-title class="center-x subtitle-1 font-weight-light">
                                    Select the Material Config
                                </v-card-title>
                                <v-card-text class="scroll-text py-0">
                                    <v-form v-model="matConfigForm">
                                        <MaterialConfigList :displayOptions="{numPerPage: 5, showSelect: true, showRetired: false}"
                                            @selected-mat-config="setSelectedMatConfig" :key="widgetSteps.matConfig.rerender"
                                        />
                                    </v-form>
                                </v-card-text>
                                <v-spacer />
                                <v-card-actions>
                                    <v-btn color="primary" outlined @click="formStepper--">
                                        Back
                                    </v-btn>
                                    <v-spacer />
                                    <v-btn color="primary" @click="formStepper++">
                                        Continue
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>

                        <v-stepper-content readonly class="pa-0" :step="widgetSteps.review.step">
                            <v-card class="outer-widget-card" height="50%">
                                <v-card-title class="center-x title font-weight-light">
                                    <span>Please review
                                    <span class="font-weight-bold"> {{ inputs.name }} </span>
                                    before saving</span>
                                </v-card-title>
                                <v-card-text class="scroll-text">
                                    <PrintFileCard v-if="inputs.printFileId"
                                        :printFile="cloud3dfArr.filter(f => f.id==inputs.printFileId)[0]"
                                        :selected="false"
                                        :clickable="false"
                                    />
                                    <v-divider class="my-4" style="width:50%; margin:auto" />
                                    <v-card v-if="inputs.matConfigId" color="gray3">
                                        <v-card-title>
                                            {{ selectedMatConfigValues.name }}
                                        </v-card-title>
                                        <v-card-text class="pb-2">
                                            <span>
                                                Created on {{ new Date(selectedMatConfigValues.created_at).toLocaleString() }}
                                                by {{ selectedMatConfigValues.user }}
                                            </span>
                                            <MaterialConfigDisplay :configData="selectedMatConfigValues" />
                                        </v-card-text>
                                    </v-card>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer />
                                    <v-btn color="primary" :disabled="disableSave" @click="saveJob()">
                                        Save
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>
import {mapState} from 'vuex';
import PrintFileCard from '../components/PrintFileCard.vue';
import MaterialConfigList from './MaterialConfigList.vue';
import MaterialConfigDisplay from './MaterialConfigDisplay.vue';

export default {
    props:[
        'usedJobNames',
    ],
    components: {
        PrintFileCard,
        MaterialConfigList,
        MaterialConfigDisplay,
    },
    data: function() {
        return {
            autoUpdateJobs: undefined,
            loading: true,
            cloud3dfArr: [],
            cardsToRender: 12,
            formStepper: 1,
            basicForm: undefined,
            matConfigForm: undefined,
            printFileSortBy: 1,
            widgetSteps: {
                basic: {
                    step: 1,
                    name: 'Basics',
                },
                printFile: {
                    step: 2,
                    name: 'Print File',
                },
                matConfig: {
                    step: 3,
                    name: 'Material Config',
                    rerender: 0, // key to rerender the component
                },
                review: {
                    step: 4,
                    name: 'Review',
                }
            },
            inputs: {
                name: undefined,
                printFileId: undefined,
                matConfigSeries: undefined,
                matConfigId: undefined
            },
            selectedMatConfigValues: undefined,
            disableSave: true,
        }
    },
    computed: {
        ...mapState([
            'dialogs',
            'baseURL',
            'rules',
        ]),
    },
    watch: {
        formStepper(val) {
            if (val == this.widgetSteps.review.step) {
                // validate inputs
                this.disableSave = !this.inputsPopulated();
            }
        },
    },
    mounted() {
        this.updatePrintFiles();
    },
    methods: {
        handleJobName() {
            this.inputs.name = this.inputs.name.trim();
        },
        async updatePrintFiles() {
            try {
                let vm = this;
                let t = await vm.$auth.getTokenSilently();
                this.loading = true;

                let cloudFiles = (await vm.$http.get(this.baseURL+"/database/printFiles", {
                    headers: { Authorization: `Bearer ${t}` }
                })).data.body;
                console.log("Got response for /database/printFiles ", cloudFiles);

                this.cloud3dfArr = [];
                for (let file of cloudFiles) {
                    file.fullName = file.first_name + " " + file.last_name;
                    this.cloud3dfArr.push(file);
                }

                this.sortPrintFile();

                this.cardsToRender = this.cloud3dfArr.length;
                this.loading = false;
            }
            catch(e) {
                console.log("Could not successfully update jobs: ", e);
            }
        },
        uniqueNameRule(v) {
            if(v && this.usedJobNames.includes(v.toLowerCase())) {
                return 'Name already used';
            }
            return true;
        },
        sortPrintFile() {
            if (this.printFileSortBy == 1) { // created at date desc
                this.cloud3dfArr.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at)) ? -1 : 1)
            }
            else if (this.printFileSortBy == 2) { // created at date asc
                this.cloud3dfArr.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at)) ? 1 : -1)
            }
            else if (this.printFileSortBy == 3) { // name asc
                this.cloud3dfArr.sort((a, b) => (a.name > b.name) ? 1 : -1)
            }
            else if (this.printFileSortBy == 4) { // name desc
                this.cloud3dfArr.sort((a, b) => (a.name > b.name) ? -1 : 1)
            }
        },
        setSelectedPrintFile(id) {
            this.inputs.printFileId = id;
        },
        setSelectedMatConfig(config) { //expecting format X-XXX
            let series, id = undefined;

            if (config) {
                [series, id] = config.displayId.split('-');
                id = parseInt(id, 10);
            }

            this.inputs.matConfigSeries = series;
            this.inputs.matConfigId = id;

            this.selectedMatConfigValues = config;
        },
        inputsPopulated() {
            if (!this.basicForm){
                return false
            }

            for (let key of Object.keys(this.inputs)) {
                if (this.inputs[key] == undefined) {
                    return false;
                }
            }

            return true;
        },
        async saveJob() {
            try {
                let t = await this.$auth.getTokenSilently();

                let params = {
                    "name": this.inputs.name, 
                    "print_file_id": this.inputs.printFileId, 
                    "material_config_id_3x":
                        this.inputs.matConfigSeries==3 ? this.inputs.matConfigId : undefined,
                    "material_config_id_2x":
                        this.inputs.matConfigSeries==2 ? this.inputs.matConfigId : undefined,
                }

                let response = await this.$http.post(this.baseURL+'/database/addJob', params, {
                    headers: { Authorization: `Bearer ${t}`}
                });

                console.log("Response from /database/addJob", response);
                this.$emit('update-jobs');
                this.dialogs.createJobForm.open = false;
                this.resetFormModal();

            }
            catch(e) {
                console.error("Failed to write job: ", e);
            }
        },
        resetFormModal() {
            // set each key in inputs to undefined
            // cant do inputs = {}, that will lose reactivity
            Object.keys(this.inputs).map((k) => {
                this.inputs[k] = undefined;
            })
            this.formStepper = 1;
            this.$refs.basicForm.reset();

            // a twisted way to reset the selection in the material config list
            this.widgetSteps.matConfig.rerender++;
            this.selectedMatConfigValues = undefined;
        }
    },
    beforeDetroy() {
        clearInterval(this.autoUpdateJobs);
    }
}
</script>

<style scoped>
.outer-widget-card {
    background-color: #272727;
    min-height: 350px;
}

.scroll-text {
    min-height: 450px;
    max-height: 55vh;
    overflow-y: auto
}

.absolute-center {
    position: absolute;
    margin: 0 auto;
}

</style>