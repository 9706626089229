<template>
<v-container>
    <v-card>
        <v-card-title>
            Compass Downloads
        </v-card-title>
        <v-card-text>
            <v-row no-gutter>
                <v-col v-for="release in compassReleases" :key="release.path">
                    <v-card flat>
                        <v-card-title class="center-x">
                            Compass {{release.version}}
                        </v-card-title>
                        <v-card-subtitle class="center-x pb-1">
                            {{release.details}}
                        </v-card-subtitle>
                        <v-card-text class="center-x py-0">
                            <v-btn color="primary"
                                @click="downloadCompass(release.bucketPath)"
                                :outlined="!release.primary"
                            >
                                <v-icon left>mdi-download</v-icon>
                                Download Exe
                            </v-btn>
                        </v-card-text>
                        <v-card-actions class="center-x">
                            Released {{release.date}}
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</v-container>
</template>

<script>
import {mapState} from 'vuex';
import {getStorageFile, storageBucketTypes} from '../assets/js/getStorageFile';


export default {
    data: function() {
        return {
            compassReleases: [],
        }
    },
    computed: {
        ...mapState([
            'internalUse',
            'baseURL',
        ]),
    },
    mounted() {
        this.compassReleases = [
            {
                version: '0.14.4',
                details: 'Customer Release',
                bucketPath: 'CompassDownloads/Compass v0.14.4.exe',
                date: 'October 6, 2023',
                primary: true
            },
        ];
    },
    methods: {
        async downloadCompass(bucketPath) {
            let t = await this.$auth.getTokenSilently();

            getStorageFile(t,
                this.baseURL,
                storageBucketTypes.ATTACHMENTS,
                undefined,
                bucketPath
            ).then((response) => {
                console.log("Got response from /database/downloadFile:", response);
                window.open(response.data.body, '_blank');
            })
            .catch((err) => {
                console.error("getStorageFile() failed. Looking for file at " +
                    bucketPath + ": " + err
                );
            })
        },
    }
};
</script>
