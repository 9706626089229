<template>
    <v-card :width="width" height="100%"
        class="d-flex flex-column"
        @click="clickedPrinter()"
        color="gray3" dark
    >
        <v-progress-linear :value="printerInfo.current_layer/printerInfo.total_layers*100" 
            height="8" :color="statusColor"
        ></v-progress-linear>
        <v-card-title class="py-1 center-x" v-if="!noHeader">
            <span class="subtitle-1 font-weight-medium">{{printerInfo.printer_name}}</span>
            <v-icon :color="statusColor" style="position:absolute; right:12px">{{currentIcon}}</v-icon>
        </v-card-title>
        <v-card-subtitle class="py-2">
            <span class="caption center-x">
                {{printerInfo.generation}} {{printerInfo.printer_type}} 
                {{ckmType[printerInfo.printer_name] ? '| CKM ' + ckmType[printerInfo.printer_name] : ''}}
            </span>
        </v-card-subtitle>
        <v-card-text>
            <v-row dense>
                <v-col cols="3" class="pb-0 font-weight-light">
                    Build:
                </v-col>
                <v-col cols="9" class="pb-0 font-weight-medium">
                    {{printerInfo.build_name}}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="3" class="pb-0 font-weight-light">
                    Status:
                </v-col>
                <v-col cols="9" class="pb-0 font-weight-medium">
                    {{displayedInfo.layers}}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="5" class="pb-0 font-weight-light">
                    Running Time:
                </v-col>
                <v-col cols="7" class="pb-0 font-weight-medium">
                    {{displayedInfo.running_time}}
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="3" class="pb-0 font-weight-light">
                    Material:
                </v-col>
                <v-col cols="9" class="pb-0">
                    <v-progress-linear :value="printerInfo.material_level/printerInfo.material_max*100"
                        height="18" color="grey darken-2" background-color="grey darken-3"
                    >
                        <span>
                            {{printerInfo.material_level}} / {{printerInfo.material_max}} mL
                        </span>
                    </v-progress-linear>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="py-0">
            <v-row dense>
                <v-col cols="2">
                <!-- <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip class="ma-2" v-bind="attrs" v-on="on" outlined
                            :color="status[printerInfo.printer_status].color" dark
                        >
                            <v-icon>mdi-hammer-wrench</v-icon>
                        </v-chip>
                    </template>
                    <span>{{printerInfo.printer_status}}</span>
                </v-tooltip> -->
                </v-col>
                <v-col cols="10" class="caption text--secondary text-end">
                    <span class="font-weight-bold">
                        {{printerInfo.location ? '@' + printerInfo.location : ''}}
                    </span>
                    | 
                    <span class="font-weight-bold">
                        FH {{printerInfo.printer_host_version}} | FW {{printerInfo.fw_print_version}}
                    </span>
                    <br/>
                    Updated: {{printerInfo.layerTime}}
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import {mapState} from 'vuex';

export default {
    props:[
        'printerInfo',
        'width',
        'noHeader'
    ],
    data: function(){
        return {
            currentIcon: undefined,
            ckmType: {
                "Mauna Kea": "LV",
                "Camelback": "LV",
                "Greylock": "LV",
                "Maxwell": "LV",
                "Moosilauke": "LV",
                "Sassafras": "LV",
                "Tremblant": "LV",
                "Baker": "FULL",
                "Batian": "LV",
                "Bazarduzu": "FULL",
                "Chiginagak": "LV",
                "Juriques": "FULL",
                "Mortimer": "FULL",
                "Si": "FULL",
            }
        }
    },
    computed: {
        ...mapState([
            'status'
        ]),
        displayedInfo: function() {
            return {
                layers : this.printerInfo.current_layer + '/' + this.printerInfo.total_layers + ' Completed',
                running_time : this.printerInfo.p_created_at == undefined ? 'Unknown' :
                    ((this.printerInfo.rawDate - new Date(this.printerInfo.p_created_at))/3600000).toFixed(1) + ' hrs',
            }
        },
        statusColor: function() {
            this.currentIcon = undefined;
            if(this.printerInfo.successful) {
                this.currentIcon = this.status['success'].icon;
                return 'grey'
            }
            if (this.printerInfo.successful == false) {
                this.currentIcon = this.status['failed'].icon;
                return 'grey'
            }
            if (new Date().getTime() - this.printerInfo.rawDate.getTime() < 300000) {
                this.currentIcon = this.status['success'].icon;
                return this.status['success'].color;
            }

            this.currentIcon = this.status['unknown'].icon;
            return 'grey'
        }
    },
    mounted() {
    },
    methods: {
        clickedPrinter() {
            let computedStats = {
                currentIcon: this.currentIcon,
                statusColor: this.statusColor
            }

            this.$router.push({
                name: 'PrinterDetails',
                params: {
                    printerName: this.printerInfo.printer_name,
                    printerStatsProp: this.printerInfo,
                    computedStatsProp: computedStats
                }
            })
        }
    }

}
</script>

<style scoped>
</style>