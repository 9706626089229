<template>
    <v-card outlined @click="$emit('select-print-file')"
        height="100%" :class="['d-flex flex-column', clickable ? '' : 'disable-events']" :color="selected ? 'primaryDark' : 'gray3'"
    >
        <v-row dense style="position: relative">
            <span v-if="selected" class="overline selected-label-position">Selected</span>
            <v-col cols="3" class="d-flex center-x pa-0">
                <v-img v-if="printFile.image_url" :src="printFile.image_url" contain max-width="133"></v-img>
            </v-col>
            <v-col>
                <v-card outlined :color="selected ? 'primaryDark' : 'gray3'" class="mr-1">
                    <v-card-title class="pa-1">
                        <span class="subtitle-1 font-weight-medium">
                            {{printFile.name}} <span class="overline grey--text pl-2">ID {{printFile.id}}</span>
                        </span>
                        <v-spacer />
                        <v-btn v-if="clickable" icon @click="getCloudFile(printFile.file_path, true)">
                            <v-icon>mdi-file-download-outline</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-subtitle class="pb-0 grey--text">
                        <span class="overline">
                            Compass {{printFile.compass_version}}
                            <span class="white--text subtitle-1 mx-2">|</span>
                            {{convertDatabaseDate(printFile.created_at)}}
                        </span>
                    </v-card-subtitle>
                    <v-divider style="width: 60%; margin: auto"></v-divider>
                    <v-card-text class="white--text pt-1 pb-0">
                        <v-row dense>
                            <v-col cols="3">
                                <span class="grey--text">Num Layers</span>
                                <br/>
                                {{printFile.num_layers}}
                                <span class="font-weight-light">layers</span>
                            </v-col>
                            <v-col cols="3">
                                <span class="grey--text">Layer Height</span>
                                <br/>
                                {{printFile.layer_height}}
                                <span class="font-weight-light">mm</span>
                            </v-col>
                            <v-col cols="3">
                                <span class="grey--text">Total Volume</span>
                                <br/>
                                {{Math.round((printFile.parts_volume||0 + printFile.supports_volume||0 + printFile.raft_volume||0)/1000)}}
                                <span class="font-weight-light">mL</span>
                            </v-col>
                            <v-col cols="3">
                                <span class="grey--text">Magnet Mode</span>
                                <br/>
                                {{printFile.magnet_mode}}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import {mapState} from 'vuex';
import {getStorageFile, storageBucketTypes} from '../assets/js/getStorageFile';

export default {
    props:[
        'printFile',
        'selected',
        'clickable',
    ],
    data: function(){
        return {
            isoImageURL: undefined
        }
    },
    computed: {
        ...mapState([
            'baseURL',
        ]),
    },
    mounted() {
        // this.getIsoViewURL();
    },
    methods: {
        convertDatabaseDate(inputDate) {
            let date = new Date(inputDate);
            return date.toLocaleString();
        },
        async getCloudFile(filePath, download) {
            // console.log("downloadFile path", filePath)
            let t = await this.$auth.getTokenSilently();

            return new Promise((resolve, reject) => {
                getStorageFile(t, this.baseURL, storageBucketTypes.CLOUD_3DFS, undefined, filePath)
                .then((response) => {
                    console.log("Got response from /database/downloadFile:", response);
                    // open signed URL in new tab
                    if(download) {
                        window.open(response.data.body, '_blank');
                    }
                    resolve(response.data.body);
                })
                .catch((e) => {
                    reject(e);
                })
            })
        },
    }

}
</script>

<style scoped>
.selected-label-position {
    position: absolute;
    left: 12px;
    z-index: 1;
}
.disable-events {
  pointer-events: none
}
</style>