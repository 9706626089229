<template>
    <v-dialog v-model="dialogs.materialConfig.open" overlay-opacity="0.85" max-width="850px">
        <v-card color="gray3">
            <v-card-title>
                {{selected.name}}
                <v-spacer />
                <v-chip :color="selected.retired? 'red' : 'green'">
                    {{selected.retired ? 'retired' : 'active'}}
                </v-chip>
            </v-card-title>
            <v-card-text>
                <v-form readonly>
                    Created on {{ new Date(selected.created_at).toLocaleString() }}
                    by {{ selected.user }}
                    <MaterialConfigDisplay :configCreationDisplay="false" :configData="selected" />
                </v-form>
            </v-card-text>
            <v-card-actions class="pb-2">
                <v-spacer />
                <v-btn outlined color="primary" 
                    @click="toggleStatus()"
                >
                    {{selected.retired ? 'Activate' : 'Retire' }} Config
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex';
import MaterialConfigDisplay from "./MaterialConfigDisplay";

export default {
    components: {
        MaterialConfigDisplay,
    },
    data() {
        return {

        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'dialogs'
        ]),
        selected: function() {
            return this.dialogs.materialConfig.details
        },
    },
    mounted(){
    },
    methods: {
        async toggleStatus() {
            let t = await this.$auth.getTokenSilently();
            console.log("calling changeMaterialStatus ", this.selected)
            let path;

            if (this.selected.config_series == 2) {
                path = '/database/changeMaterialStatus'
            }
            else if (this.selected.config_series == 3) {
                path = '/database/changeMatStatus3x'
            }

            this.$http.post(this.baseURL+path, {
                status: !this.selected.retired,
                configId: this.selected.id
            },{
                headers: {
                    Authorization: `Bearer ${t}`
                }
            })
            .then((response) => {
                this.dialogs.materialConfig.open = false;
                console.log("Got response for ", path, " :",  response);
                this.$emit('update-material-configs');
            })
            .catch((error) => {
                console.log("Unable to retire material config: ", path, error);
                return;
            });
        },
        concatParamValue(value, units) {
            let temp = 'N/A';
            if (!value) {
                return temp;
            }
            return temp = value + (units ? ' ' + units : '');
        }
    }

}
</script>