import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
      },
      theme: {
        dark: true,
        themes: {
          light: {
            primary: "#EA492F",
            primaryDark: "#492E28",
            primaryDark2: "CF5743",
            secondary: "#36A29F", //#5285AC",
            tertiary: "#30859c", //"#727373"
            green: "#36A29F",
            blue: "#30859c",
          },
          dark: {
            primary: "#EA492F",
            primaryDark: "#492E28",
            primaryDark2: "CF5743",
            secondary: "#36A29F",
            tertiary: "#30859c",
            green: "#36A29F",
            blue: "#30859c",
            darkgray: "#272727",
            lightgray: "#727373",
            gray3: "#363636", 
            success: "#36A29F",
            darkgray2: "#292929"
          }
        }
    }
});
