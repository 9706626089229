<template>
    <v-card class="ma-3" :loading="printerStats==undefined">
        <v-card-title v-if="printerStats!=undefined">
            <span class="overline">{{printerStats.printer_type}}</span>
            <v-spacer />
            {{printerName}}
            <v-spacer />
            <v-icon :color="computedStats.statusColor" large>{{computedStats.currentIcon}}</v-icon>
        </v-card-title>

        <v-card-text v-if="printerStats!=undefined">
            <v-row class="center-x">
                <v-col cols=12 lg=6 class="py-0">
                    <DisplayPrinterScreen :printerName="printerName" :width="'100%'"/>
                </v-col>
                <v-col cols=12 lg=6>
                    <v-row class="center-y">
                        <v-col cols=12 md=6>
                            <PrinterStatCard :printerInfo="printerStats" :height="225" :noHeader="true" />
                        </v-col>
                        <v-col cols=12 md=6>
                            <v-card height="220" dark color="gray3">
                                <v-card-title class="secondary--text">LifeTime Counts</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="5" class="pb-0 font-weight-light">
                                            Total Prints:
                                        </v-col>
                                        <v-col cols="7" class="pb-0 font-weight-medium">
                                            {{totalPrints}} prints
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="pb-0 font-weight-light">
                                            Total Print Time:
                                        </v-col>
                                        <v-col cols="7" class="pb-0 font-weight-medium">
                                            {{totalPrintingHours}} hours
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="5" class="pb-0 font-weight-light">
                                            Total Layers:
                                        </v-col>
                                        <v-col cols="7" class="pb-0 font-weight-medium">
                                            {{totalLayers}} layers
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col cols=12 xs=12 sm=4>
                            <SuccessPercentage title="Overall Success Rate"
                                :successfulPercentage="successRate" :totalPrints="totalPrints" color="tertiary"/>
                        </v-col>
                    </v-row> -->
                </v-col>
            </v-row>
            <!-- <PrinterChanges
                :printerChangesCombined="printerChanges"
                :rawPrinterChanges="printerChanges"
                :loadingBarOn="printerLogsLoading"
                :printerName="printerName"
                @update-logs="updatePrinterLogs()"
            /> -->
            <PrintList :title="'Prints on ' + printerName " 
                :rawPrints="rawPrints" :loading="printsLoadingBarOn" :entriesPerPage="5"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import {mapState} from 'vuex';
import DisplayPrinterScreen from '../components/DisplayPrinterScreen';
// import PrinterChanges from './tables/PrinterChanges';
import PrinterStatCard from '../components/PrinterStatCard';
import PrintList from '../components/PrintList';
// import SuccessPercentage from './charts/SuccessPercentage.vue';

export default {
    props:[
        'printerName',
        'printerStatsProp',
        'computedStatsProp',
    ],
    components: {
        DisplayPrinterScreen,
        // PrinterChanges,
        PrinterStatCard,
        PrintList,
        // SuccessPercentage,
    },
    data: function(){
        return {
            printerStats: undefined,
            computedStats: undefined,
            layerStatus: '0/0 Complete',
            runningTime: '0 hrs',
            printsLoadingBarOn: true,
            rawPrints: [],
            printResultsForDisplay: [],
            totalPrints: 0,
            totalPrintingHours: 0,
            totalLayers: 0,
            totalSuccessfulPrints: 0,
            printerId: undefined,
            printerChanges: [],
        }
    },
    computed: {
        ...mapState([
            'baseURL',
            'status',
            'statusIcons',
        ]),
        successRate: function() {
            if (this.totalPrints != 0) {
                return Math.round(this.totalSuccessfulPrints/this.totalPrints*100);
            }
            return 0;
        }
    },
    async mounted() {
        let vm = this;

        // if this is a direct URL to the page, need to rebuild all info
        if (!this.printerStatsProp && !this.computedStatsProp) { 
            this.updateLayerData();
        }
        else {
            this.printerStats = this.printerStatsProp;
            this.computedStats = this.computedStatsProp;
        }
        this.autoUpdateLayerData = setInterval(this.updateLayerData, 20000);

        // this.autoUpdatePrinterLogs = setInterval(this.updatePrinterLogs, 300000); // every 5 mins
        // this.updatePrinterLogs();

        // get the printer id from the database
        let t = await this.$auth.getTokenSilently();
        vm.$http.get(this.baseURL+"/database/printerIdByName", {
            headers: { Authorization: `Bearer ${t}` },
            params: {param: this.printerName}
        })
        .then((response) => {
            this.printerId = response.data.body;
            this.getPrints(response.data.body);
            this.autoUpdatePrints = setInterval(()=>{this.getPrints(this.printerId)}, 60000);
        });
    },
    methods: {
        async updateLayerData() {
            let vm = this;
            let t = await this.$auth.getTokenSilently();

            vm.$http.get(this.baseURL+"/database/recentPrints", {
                headers: { Authorization: `Bearer ${t}` },
            })
            .then((response) => {
                console.log("Got response for /database/recentPrints ", response);
                if (response.data.body) {
                    for (let index = 0; index < response.data.body.length; index++) {
                        if(response.data.body[index].printer_name == this.printerName) {
                            vm.printerStats = response.data.body[index];
                            let date = new Date(vm.printerStats.l_created_at);
                            vm.printerStats.layerTime = date.toLocaleDateString() + " " + date.toLocaleTimeString();
                            vm.printerStats.rawDate = date;
                            vm.layerStatus = vm.printerStats.current_layer + '/' + vm.printerStats.total_layers + ' Completed';
                            vm.runningTime = vm.printerStats.p_created_at == undefined ? 'Unknown' :
                                ((new Date(vm.printerStats.layerTime) - new Date(vm.printerStats.p_created_at))/3600000).toFixed(1) + ' hrs';

                            // set printer icon and status color
                            vm.computedStats = {};
                            vm.computedStats.statusColor = 'grey';
                            vm.computedStats.currentIcon = vm.status['unknown'].icon;
                            if(vm.printerStats.successful) {
                                vm.computedStats.currentIcon = vm.status['success'].icon;
                            }
                            if (vm.printerStats.successful == false) {
                                vm.computedStats.currentIcon = vm.status['failed'].icon;
                            }
                            if (new Date().getTime() - vm.printerStats.rawDate.getTime() < 300000) {
                                vm.computedStats.currentIcon = vm.status['success'].icon;
                                vm.computedStats.statusColor = vm.status['success'].color;
                            }
                            break;
                        }
                    }
                }
            })
        },
        async updatePrinterLogs() {
            let t = await this.$auth.getTokenSilently();

            this.printerLogsLoading = true;
            this.$http.get(this.baseURL+"/database/printerChangeLogs", {
                headers: { Authorization: `Bearer ${t}` }
            })
            .then((response) => {
                console.log("Got response from server for /database/printerChangeLogs: ", response.data.body);
                this.printerChanges = [];
                response.data.body.forEach(row => {
                    if (row.printer == this.printerName) {
                        this.printerChanges.push(row);
                    }
                });
                this.printerLogsLoading = false;
            })
        },
        async getPrints(printerId) {
            let vm = this;
            let t = await this.$auth.getTokenSilently();

            console.log("Requesting /database/customSearch");
            this.$http.get(this.baseURL+'/database/customSearch', {
                headers: { Authorization: `Bearer ${t}` },
                params: {param: {printerName: [printerId], uniquePrintID: true}}
            })
            .then((response) => {
                console.log("Response for /database/customSearch: ", response.data.body);
                vm.rawPrints = response.data.body;
                vm.totalPrints = vm.rawPrints.length;
                vm.printsLoadingBarOn = false;

                this.calculateStats();
            })
        },
        calculateStats() {
            if (this.rawPrints.length == 0) { return }

            this.totalPrintingHours = 0;
            this.totalLayers = 0;
            this.totalSuccessfulPrints = 0;

            this.rawPrints.forEach((result) => {
                this.totalSuccessfulPrints += result.successful ? 1 : 0;
                this.totalLayers += result.total_layers;

                if (result.end_time) {
                    let tempDuration = (new Date(result.end_time) - new Date(result.printed_at))/3600000;
                    this.totalPrintingHours += tempDuration;
                }
            });

            this.totalPrintingHours = Math.round(this.totalPrintingHours);
        }
    },
    beforeDestroy () {
        clearInterval(this.autoUpdateLayerData);
        clearInterval(this.autoUpdatePrints);
        clearInterval(this.autoUpdatePrinterLogs);
    }
}
</script>

<style lang="scss">

</style>